import React, { useState } from 'react'
import { IonContent, IonPage, IonRow, IonCol, IonButton, IonGrid, IonThumbnail, IonToast, IonText, IonHeader, IonToolbar, IonActionSheet } from '@ionic/react';
import './ProfilePhoto.css';
import { RouteComponentProps, useHistory } from 'react-router';
import { API } from '../../constants';
import { camera, image, close } from 'ionicons/icons';

import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}

interface ProfilePhotoProps extends OwnProps, DispatchProps { }

//const ProfilePhoto: React.FC<ProfilePhotoProps> = ({history}) => {

  const ProfilePhoto: React.FC = () => {
  const history = useHistory();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [photoUrl, setPhotoUrl] = useState<any>("");
  const [imageBase64Url, setImageBase64Url] = useState<any>("");
  const [imageFormat, setImageFormat] = useState<any>("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onChoosePhoto = async(source: CameraSource)=>{

    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      correctOrientation : true,
      saveToGallery: false,
      resultType: CameraResultType.Base64,
      source
    });
    const base64Image = 'data:image/'+image.format +';base64,'+ image.base64String;
    setPhotoUrl(base64Image);
    setImageFormat(image.format);
    setImageBase64Url(image.base64String);
    setErrorMessage('');
  }

  const onClickUploadPhoto = () =>{
    // if(photoUrl){
      if(localStorage.getItem("registerData") != null){
        const profilePhotoJson = localStorage.getItem("registerData");
        const profileJson = profilePhotoJson !== null ? JSON.parse(profilePhotoJson) : '';
        profileJson.photo = imageBase64Url;
        /* if(imageBase64Url){
          uploadPicture(imageBase64Url, imageFormat);
        } */
        localStorage.setItem('registerData', JSON.stringify(profileJson));
        history.push('pronouns');
        setShowToast(true);
        setToastMessage('Image Choose Successfully..');
      }else{
        setErrorMessage('Fill Previos form');
      }     
   /*  }else{
      setErrorMessage('Please choose image');
    } */
  }

  /* const uploadPicture = async (base64Image : any, image: any) =>{
    if(base64Image){
      const editDataJson = localStorage.getItem('registerData');
      const data = editDataJson !== null ? JSON.parse(editDataJson) : '';

      const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + localStorage.getItem('_token') },
        body: JSON.stringify({photo: String(base64Image), format: image, student_id:data.id, is_profile:1 }) 
      };
      const res: Response = await fetch(API.API_LIVE+'account/upload_image', requestOptions);
      res.json().then(async (res) => {
        if(res.status === true){
          console.log('image uploaded succssfully..');
        }else{
          console.log('image not uploaded..');
        }
      }).catch(err => console.error(err)); 
    }else{
      setShowToast(true);
      setToastMessage('Please choose image');
    }
  } */



  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="">
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-top extraPad">
      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      <IonGrid className="profilePic">
        <IonRow className="ion-justify-content-between profileContent">
          <IonCol size="12" className="headContent ion-text-center">
            <h2 className="">Add Your Photo</h2>
            <p>Show Off Those Pearly Whites!</p>
            {errorMessage && <IonText color="danger"> <p className="dangerMessage">
      {errorMessage} </p> </IonText>}
          </IonCol>
         
          <IonCol size="12" className="ion-text-center">
            <IonThumbnail onClick={() => setShowActionSheet(true)} >
              {!photoUrl && <img alt="name" src="/assets/img/default.svg" />}
              {photoUrl && <img alt="name" src={photoUrl} />}
              <i className="material-icons">add_circle</i>
            </IonThumbnail>
          </IonCol>


          <IonActionSheet isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            cssClass='my-custom-class'
            buttons={[{ text: 'From Photos', role: 'destructive', icon: image,
              handler: () => { 
                onChoosePhoto(CameraSource.Photos);
              }
            }, { text: 'Take Picture', icon: camera,
              handler: () => { 
                onChoosePhoto(CameraSource.Camera);
              }
            }, { text: 'Cancel', icon: close, role: 'cancel',
              handler: () => {  
               
              }
            }]} >
        </IonActionSheet>

          <IonCol size="12">
            <IonButton expand="block" onClick={onClickUploadPhoto} >UPLOAD PHOTO</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default ProfilePhoto;
