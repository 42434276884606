import React, { useContext, useState} from 'react';
import { IonContent, IonPage, IonItem, IonAvatar, IonRow, IonCol, IonInput, IonButton, IonGrid, IonText, IonLoading, IonToast, IonHeader, IonToolbar } from '@ionic/react';
import { Link } from "react-router-dom";
import './Login.css';

import { UserContext } from "../../App";
import { API } from '../../constants';
import { RouteComponentProps } from 'react-router';

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}
interface LoginProps extends OwnProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({history}) => {  

  const [email, setEmail] = useState<string>(""); 
  const [verifycode, setVerifyCode] = useState<string>(""); 
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [checkEmail, setCheckEmail] = useState(false);
  
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);

  const user = useContext(UserContext);
  
  setTimeout(() => { setErrorMessage(''); }, 8000);

  const onEmailChnage = (email : any) =>{
    if(email.length >= 0){ setEmailError(false);
      setEmail(email);
    }else{ setEmailError(true); } 
  }

  const onVerifyCodeChnage = (verifycode : any) =>{
    if(verifycode.length >= 0){ setVerifyCodeError(false); setSuccessMessage('');
      setVerifyCode(verifycode);
    }else{ setVerifyCodeError(true); } 
  }

  const loginClick = async (e: React.FormEvent) => {
    setShowToast(false);
    e.preventDefault();
    setFormSubmitted(true);

    if(email){
      setShowLoading(true);
      // Post Data
      const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
      };
      // setCheckEmail(true);
      fetch(API.API_LIVE+'login', requestOptions).then(async response => {
        const data = await response.json();
        
        if(data.status !== false){
          setCheckEmail(true);
          //setSuccessMessage(data.message);
        }else{
          setErrorMessage(data.message);
        }
        setToastMessage(data.message);
        setShowToast(true);
        setShowLoading(false);
        
      }).catch(error => {
        setErrorMessage(error.toString());
      }); 
      // Post Data
    }else{
        setShowLoading(false);
        setEmailError(true);
    }
 };



  const verifyClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowLoading(true);
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, code: verifycode })
    };
   
    fetch(API.API_LIVE+'verifyotpcode', requestOptions).then(async response => {
      const data = await response.json();
      if(data.status !== false){
       // setSuccessMessage(data.message);
        setToastMessage(data.message);
        setShowToast(true);
        setErrorMessage('');
        localStorage.setItem('_isLogin', 'true');
        localStorage.setItem('_token', data.token);
        localStorage.setItem('_userData', JSON.stringify(data.user));
    
        if(data.user.dob ===''){
          localStorage.setItem('comeFrom1', 'login');
          history.push('/birthday-edit', {direction: 'none'});
        }else{
          history.push('/home', {direction: 'none'});
        } 
        await user.setIsLoggedIn(true);
      
      }else{
        setErrorMessage(data.message);
      }
      setShowLoading(false);
    }).catch(error => {
      setErrorMessage(error.toString());
    });
    
  };

  const resendOTP =() =>{
    setShowLoading(true);
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email })
    };

    fetch(API.API_LIVE+'resendotpcode', requestOptions).then(async response => {
      const data = await response.json();
      if(data.status !== false){
        setToastMessage(data.message);
        setShowToast(true);
        setErrorMessage('');
      }else{
        setErrorMessage(data.message);
      }
      setShowLoading(false);
    }).catch(error => {
      setErrorMessage(error.toString());
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-top">
      {toastMessage && <IonToast color="dark"  isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      <IonLoading isOpen={showLoading} backdropDismiss={true}  message="Logging in..." onDidDismiss={() => setShowLoading(false)}/>
      <IonGrid className="loginGrid">
       <div className="logBottom"> 
        <IonRow className="ion-margin-bottom">
          <IonCol size="12" className="ion-text-center">
            <IonAvatar className="ion-text-center DNB">
              <img alt="app_logo" src="/assets/img/logo.svg"  />
            </IonAvatar>
            <h2 className="ion-text-center">
              Get To Know GSB'22
            </h2>
          </IonCol>
        </IonRow>
        <IonRow className="home-spacer">
          <IonCol size="12">
          {errorMessage && <IonText color="danger"> <p className="ion-padding-start dangerMessage">
          {errorMessage} </p> </IonText>}

          {successMessage && <IonText color="success"> <p className="ion-padding-start successMessage">
          {successMessage} </p> </IonText>}

            {!checkEmail && <form noValidate onSubmit={loginClick}>
              <IonItem>
                <IonInput name="email" type="email" placeholder="Email Address" value={email} spellCheck={false} autocapitalize="off" onIonChange={e => onEmailChnage(e.detail.value!)}
                required ></IonInput>
              </IonItem>
              {formSubmitted && emailError && <IonText color="danger">
                <p className="ion-padding-start"> Email is required </p>
              </IonText>}
              <IonButton expand="block" color="primary" className="ion-text-uppercase ion-margin-vertical" type="submit" >Login</IonButton>
          </form> }

          {checkEmail && <form noValidate onSubmit={verifyClick}>
              <IonItem>
                <IonInput name="verifycode" type="number" inputMode="numeric" pattern="[0-9]*"  placeholder="Enter Code" value={verifycode} onIonChange={e => onVerifyCodeChnage(e.detail.value!)}
                required ></IonInput>
              </IonItem>
              {formSubmitted && verifyCodeError && <IonText color="danger">
                <p className="ion-padding-start"> Verifcation code is required </p>
              </IonText>}
              <p className="ion-text-center">Didn't recieved code? <a onClick={resendOTP}>Resend OTP</a> </p>
              <IonButton expand="block" color="primary" className="ion-text-uppercase ion-margin-vertical" type="submit" >Verify Code</IonButton>
          </form> }

          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className="ion-text-center" size="12">
          <IonText> <p>Don't Have an account? <Link to="/join-class">Create Account</Link> </p></IonText>
          </IonCol>
        </IonRow>

        </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;