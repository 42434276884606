
import React, { useState, useContext, useEffect } from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonRow, IonCol, IonInput, IonButton, IonGrid, IonText, useIonViewWillEnter, IonToast, IonButtons, IonBackButton, IonIcon, IonAvatar, IonSelect, IonSelectOption} from '@ionic/react';
import '../before-gsb/BeforeGSB.css';
import { API, AppLogout } from '../../constants';
import { arrowBack, cogSharp } from 'ionicons/icons';
import { RouteComponentProps, useHistory} from 'react-router';
import { UserContext } from "../../App";

const Birthday: React.FC= () => {

  const history = useHistory();
  const user = useContext(UserContext);
   
  const [comeFrom, setComeFrom] = useState<any>("");
  const [showLoading, setShowLoading] = useState(false);
  const [submitButton, setSubmitButton] = useState<string>("Next");
  const [editData, setEditData] = useState<any>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);  


  const [yearData, setYearData] = useState<any>([]);
  const [dateData, setDateData] = useState<any>([]);
  
  const [currentMonth, setCurrentMonth] = useState<string>();
  const [currentDate, setCurrentDate] = useState<number>(0);
  const [currentYear, setCurrentYear] = useState<number>(0);

  useEffect(() => {
    // Get Current Year
    const year = new Date().getFullYear();
    setCurrentYear(year);

    /* for (let i:any = year-70; i <= year; i++) { 
      yearData.push({'year': i});
      setYearData(yearData);
    }
    setYearData(yearData); */

    // Get Current Month
    const month = new Date().getMonth();
    var finalMonth = month + 1;
    setCurrentMonth(finalMonth.toString());

     // Get Date
    const date = new Date().getDate();
    setCurrentDate(date);
    for (let i:any = 1; i <= 31; i++) { 
      dateData.push({'date': i});
      setDateData(dateData);
    }
  }, [yearData, dateData]);
  
  useIonViewWillEnter(() => {
    if(localStorage.getItem('_userData') !== null){
        const editDataJson = localStorage.getItem('_userData');
        setComeFrom(localStorage.getItem('comeFrom'));
        const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
        setEditData(data);
        setCurrentMonth(data.month);
        setCurrentDate(data.date);
        setCurrentYear(data.year);
        setSubmitButton('Update');      
    }else{
      setSubmitButton('Next');
      setEditData(null)
    }
  });

  const onClickNext = async () => {

    const year = new Date().getFullYear();

    if((currentMonth !== '' && currentDate.toString() === '')  || (currentMonth === '' && currentDate.toString() !== '') ){
      setShowToast(true);
      setToastMessage('Please select month and date.');
    } else {

      if(!editData){
        // Register Data
        const registerDataJson = localStorage.getItem("registerData");
        const dobJson = registerDataJson !== null ? JSON.parse(registerDataJson) : '';
        dobJson.dob= year+'-'+currentDate+'-'+currentMonth;
        localStorage.setItem('registerData', JSON.stringify(dobJson));
        history.push('/relationship-status', {direction: 'none'});
        // Register Data
      }else{
          // Edit Data
          setShowLoading(true);
          const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' , Authorization: 'Bearer ' + localStorage.getItem('_token')},
            body: JSON.stringify({ student_id: editData.id, dob: year+'-'+currentDate+'-'+currentMonth })
          };
  
          fetch(API.API_LIVE + 'account/update_bio', requestOptions).then(async response => {
            const data = await response.json();
            if(data.status !== false){
              localStorage.setItem('comeFrom', 'edit');
  
             if(localStorage.getItem('comeFrom1') === 'login'){
                history.push('/home', {direction: 'none'});
                history.replace('/', {direction: 'none'});

                const userDataJson1 = localStorage.getItem('_userData');
                const dataJson = userDataJson1 !== null ? JSON.parse(userDataJson1) : '';
                dataJson.dob = data.dob;
                dataJson.date = data.date;
                dataJson.month = data.month;
                dataJson.year = data.year;
                localStorage.setItem('_userData', JSON.stringify(dataJson));
                localStorage.removeItem('comeFrom1');
                
              }else{
                history.goBack();
              }
              setToastMessage(data.message);
              setShowToast(true);
            }else{
              if(data.code === '401'){
                goLogout(data.message);
              }else{
                setToastMessage(data.message);
                setShowToast(true);
              }
            }
            setShowLoading(false);
          }).catch(error => {
            setShowLoading(false);
          });
          // Edit Data
        }    
      }
  }
  
  const goBackPage = ()=>{ history.goBack(); }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }

  return (
    <IonPage>
      {editData && <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="dark">
             {comeFrom === 'edittable' && <IonButton color="primary" onClick={goBackPage}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>}
          </IonButtons>
          <IonTitle>Birthday</IonTitle>
        </IonToolbar>
      </IonHeader>}
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent className="birthday__enter__Page">

      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)} />}

      <IonGrid className="profilePic ion-padding-horizontal">

          <IonRow className="ion-margin-bottom">
            <IonCol size="12" className="ion-text-center">
              <IonAvatar className="ion-text-center DNB">
                <img src="/assets/img/logo.svg" alt="Application Logo"/>
              </IonAvatar>
              <h2 className="ion-text-center"> Enter Your Birthday </h2>
              <p>Because No One Uses Facebook Anymore...</p>
            </IonCol>
          </IonRow>
        <div className="birthdaySpacer">
            <IonRow className="ion-justify-content-center  ">
              <IonCol size="6" className="headContent ion-text-center">
              <IonItem>
              <IonLabel class="ion-hide">Month</IonLabel>
                <IonSelect value={currentMonth} okText="Okay" cancelText="Dismiss" onIonChange={e => setCurrentMonth(e.detail.value)} placeholder="Select Month">
                  <IonSelectOption value="1">January</IonSelectOption>
                  <IonSelectOption value="2">February</IonSelectOption>
                  <IonSelectOption value="3">March</IonSelectOption>
                  <IonSelectOption value="4">April</IonSelectOption>
                  <IonSelectOption value="5">May</IonSelectOption>
                  <IonSelectOption value="6">June</IonSelectOption>
                  <IonSelectOption value="7">July</IonSelectOption>
                  <IonSelectOption value="8">August</IonSelectOption>
                  <IonSelectOption value="9">September</IonSelectOption>
                  <IonSelectOption value="10">October</IonSelectOption>
                  <IonSelectOption value="11">November</IonSelectOption>
                  <IonSelectOption value="12">December</IonSelectOption>
                </IonSelect>
              </IonItem> 
              </IonCol>

              <IonCol size="4" className="headContent ion-text-center">
                <IonItem>
                <IonLabel class="ion-hide">Date</IonLabel>
                  <IonSelect value={currentDate} okText="Okay" cancelText="Dismiss" onIonChange={e => setCurrentDate(e.detail.value)} placeholder="Date">
                    <div> {dateData.map((date: any, i: number) => {
                      return <IonSelectOption value={date.date} key={`${i}`}>{date.date}</IonSelectOption>
                    })} </div>
                  </IonSelect>
                </IonItem>
              </IonCol>

            

              {/* <IonCol size="4" className="headContent ion-text-center">
                <IonItem>
                <IonLabel class="ion-hide">Year</IonLabel>
                  <IonSelect value={currentYear} okText="Okay" cancelText="Dismiss" onIonChange={e => setCurrentYear(e.detail.value)} placeholder="Year">
                    <div> {yearData.map((year: any, i: number) => {
                      return <IonSelectOption value={year.year} key={`${i}`}>{year.year}</IonSelectOption>
                    })} </div>
                  </IonSelect>
                </IonItem>
              </IonCol> */}
            
              
            </IonRow>
            <IonRow>
              <IonCol size="12">
                {!showLoading && <IonButton color="primary" onClick={() => onClickNext()} expand="block">{submitButton}</IonButton>}
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Birthday;
