import React, { useState, useContext, useEffect } from "react";
import { Route } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from "./pages/login/Login";
import JoinClass from './pages/join-class/JoinClass';
import MainTabs from "./pages/mainTabs";

import ProfilePhoto from './pages/profile-photo/ProfilePhoto';
import Pronouns from './pages/pronouns/Pronouns';
import YouLike from './pages/you-like-to-do/YouLike';
import Favorite from './pages/favorite/Favorite';

import BeforeGSB from './pages/before-gsb/BeforeGSB';
import Birthday from './pages/birthday/Birthday';
import RelationshipStatus from './pages/relationship-status/RelationshipStatus';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Verify } from "crypto";
/* 
import { Plugins, StatusBarStyle, } from '@capacitor/core';
const { StatusBar } = Plugins; */

interface IUserManager { setIsLoggedIn: Function; }
const user: IUserManager = { setIsLoggedIn: () => {} };


export const UserContext =  React.createContext<IUserManager>(user);

const IonicApp: React.FC = () => {  
 
  const user = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  user.setIsLoggedIn = setIsLoggedIn;
 
  useEffect(() => {
    const _isLogin = localStorage.getItem('_isLogin');
    if(_isLogin !== null){
      if(_isLogin === 'true'){
        user.setIsLoggedIn(true);
      }else{
        user.setIsLoggedIn(true);
      }
    }  
  });

 // StatusBar.setBackgroundColor

  return (
    <IonApp>
      <IonReactRouter>
            <Route path="/" component={ isLoggedIn ? MainTabs : JoinClass } />
             {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
            <Route path="/login" component={Login} />
            <Route path="/join-class" component={JoinClass} />
            <Route path="/profile-photo" component={ProfilePhoto} />
            <Route path="/pronouns" component={Pronouns} />
            <Route path="/you-like-to-do" component={YouLike} />
            <Route path="/favorite" component={Favorite} />
            <Route path="/before-gsb" component={BeforeGSB} />
            <Route path="/birthday" component={Birthday} />
            <Route path="/relationship-status" component={RelationshipStatus} />
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {
  return (
    <UserContext.Provider value={user}>
      <IonicApp />
    </UserContext.Provider>
  );
};

export default App;
