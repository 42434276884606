import React, { useState} from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonRow, IonCol, IonTextarea, IonButton, IonText, IonButtons, IonBackButton, IonProgressBar, IonToast, useIonViewWillEnter, IonLoading, IonAlert, IonActionSheet, IonTitle} from '@ionic/react';
import { camera, image, close } from 'ionicons/icons';
import './GiveSome.css';
import { API } from '../../constants';
import { RouteComponentProps, useHistory } from 'react-router';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;


interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}
interface GiveSomeProps extends OwnProps, DispatchProps { }

/* const GiveSome: React.FC<GiveSomeProps> = ({history}) => { */ 
const GiveSome: React.FC = () => {
  const history = useHistory();
  const [searchId, setSearchId] = useState<number>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [information, setInformation] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [informationError, setInformationError] = useState(false);
  const [comeFrom, setComeFrom] = useState<any>("");
  const [showLoading, setShowLoading] = useState(false);
  const imageDataJson = localStorage.getItem('_imageData');
  const image_data = imageDataJson !== null ? JSON.parse(imageDataJson) : [];

  const [userData, setUserData] = useState<any>("");
  const [listUserData, setListUserData] = useState<any>("");
  const [validationMessage, setValidationMessage] = useState<string>("");

  const [toastMessage, setToastMessage] = useState<string>("");
  const [progressValue, setProgressValue] = useState<number>(0);
  const [shoutoutImage, setShoutoutImage] = useState<string>("");
  const [shoutoutImageData, setShoutoutImageData] = useState<any>("");
  
  const [showToast, setShowToast] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);

  useIonViewWillEnter(async() => {

    const comeFromPage = localStorage.getItem('comeFrom');
    if(comeFromPage !== null){ 
     setComeFrom(comeFromPage);
    } else { setComeFrom(''); }

    if(localStorage.getItem('_isLogin') !== 'false'){
      const userDataJson = localStorage.getItem('_userData');
      if(localStorage.getItem('_userData') !== null && userData === ''){
        const data = userDataJson !== null ? JSON.parse(userDataJson) : '';
        setUserData(data);
      }
    const listDataJson = localStorage.getItem('listData');
      if(localStorage.getItem('listData') !== null && userData === ''){
        const listUser = listDataJson !== null ? JSON.parse(listDataJson) : '';
        setListUserData(listUser);
        setSearchId(listUser.id);
      }
    }  
  });

  
  
  const onInformationChange = (information : any) =>{
    if(information.length >= 0){
      //if(information.length <= 500){
        setInformationError(false); setValidationMessage('');
        setInformation(information);
      /* }else{
        setInformationError(true);
        setInformation(information);
        setValidationMessage('Enter only 500 character');
      } */
    }else{ setInformationError(true); setValidationMessage('Enter post information.'); } 
  }

  //const onSubmitClick = async () => {
  const onSubmitClick = async (e: React.FormEvent) => {
    e.preventDefault();

    var isText : boolean = true;
    if(information){
      if(information.length > 9000){
        isText = false
        setShowToast(true);
        setToastMessage('Enter max 9000 characters only.');
      }
    }else{ isText = false
      setInformationError(true); setValidationMessage('Enter post information.');
    }

    if(isText){
      setFormSubmitted(true);
      setShowLoading(true);
      //if(information.length <= 500){
        const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token') },
          body: JSON.stringify({ from: userData.id, to:searchId, praise: information, photo: shoutoutImageData})
        };
        const res: Response = await fetch(API.API_LIVE+'praise/post', requestOptions);
        res.json().then(async (res) => { 
         
          if(res.status !== false) {  
            setShowToast(true);
            setToastMessage(res.message);
            afterPostResetFileds();
            localStorage.removeItem('listData');
            setListUserData('');
            
            history.push('home', {direction: 'none'});
            history.replace('', {direction: 'back'});

            localStorage.setItem('from', 'add');
            localStorage.removeItem('_feedData');
            setShoutoutImage('');
            setProgressValue(0);
            setShoutoutImageData('');
          } else {
            setShowToast(true);
            setToastMessage(res.message);
            setShowAlert1(true);
          }
          setShowLoading(false);
          setFormSubmitted(false);
        }).catch(err => { });
      //}else{ setInformationError(true); setValidationMessage('Enter only 500 character'); }
    }
  }

  function afterPostResetFileds(){
    setInformation(''); setInformationError(false);
  }

  const addImage = async (source: CameraSource) => {
    const image = await Camera.getPhoto({ quality: 90, allowEditing: true, resultType: CameraResultType.Base64, source });

    setProgressValue(0.1);
    setProgressValue(0.3);
    setProgressValue(0.5);
    setProgressValue(0.8);
    setProgressValue(1.0);

    var temp_timestamp = new Date().getTime();
    const imageName =  'image_'+ temp_timestamp + '.' +image.format;
    setShoutoutImage(imageName);
    setShoutoutImageData(image.base64String);
    
  }

  const onRemovePhoto = async()=>{
    setShoutoutImage('');
    setProgressValue(0);
    setShoutoutImageData('');
  }

  return (
    <IonPage className="searchPage givesomePage">
      <IonHeader >
        <IonToolbar>
          
          <IonButtons slot="start"> 
            {comeFrom === 'home' && <IonBackButton defaultHref="my-class" color="secondary" />}
            {comeFrom === 'birthday' &&  <IonBackButton defaultHref="home" color="secondary" />}
            {comeFrom === 'birthday-list' && <IonBackButton defaultHref="birthday-list" color="secondary" />}
          </IonButtons>
          <IonTitle>Give a Shoutout</IonTitle>

          <IonButtons slot="end">
            <IonBackButton defaultHref="my-class" icon="" color="secondary" text="Cancel" />
          </IonButtons>
        {/*   <IonTitle>Give a Shoutout</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-bottom">

      <IonAlert isOpen={showAlert1} onDidDismiss={() => setShowAlert1(false)} cssClass='my-custom-class' header={'Praise Post'} message={toastMessage} buttons={['OK']} />
  
      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)} />}
    
      <IonLoading isOpen={showLoading} message="Sending Post..." onDidDismiss={() => setShowLoading(false)}/>

        <form noValidate onSubmit={onSubmitClick}>
          <IonRow>
            {/* <IonCol size="12" className="ion-margin-bottom">
              <h2 className="headTitle">Give a Shoutout</h2>
            </IonCol> */}
            <IonCol size="12" className="ion-text-center ion-margin-bottom">
              <span className="givesomeImage">
                {listUserData.photo_url && <img src={  listUserData.photo_url_100} alt="User Profile" />}
              </span>
              <h5>{listUserData.first_name} {listUserData.last_name} </h5>
            </IonCol>
          </IonRow>

          <IonItem className="">
            <IonTextarea value={information} placeholder="Enter more information here..." onIonChange={e => onInformationChange(e.detail.value!)} required></IonTextarea>
          </IonItem>
            {informationError && <IonText color="danger">
            {validationMessage && <p className="ion-padding-start ion-text-left dangerMessage"> {validationMessage}</p>}
          </IonText>}

          <IonRow>
            <IonCol size="12" className="photoName">
            {shoutoutImage && <p className="d-flex ion-align-items-center ion-justify-content-between">
                {shoutoutImage} <i className="material-icons" onClick={(event) => onRemovePhoto()}>close</i>
              </p>}
              {progressValue !== 0 && <IonProgressBar color="primary" value={progressValue}></IonProgressBar>}
            </IonCol>
            {!shoutoutImage && <p className="ion-margin-start"> No image selected.</p>}
          </IonRow>
        
          <IonRow className="">
            <IonCol size="12" className="photoName ion-margin-bottom">
              <IonButton color="dark" fill="clear" className="ion-no-padding photoButton" onClick={() => setShowActionSheet(true)}><i className="material-icons">add_circle</i>Add Photo</IonButton>
            </IonCol> 
            
            {!formSubmitted && <IonCol size="12" className="photoName">
              <IonButton expand="full" color="primary" type="submit" shape="round" >Post</IonButton>
            </IonCol>}

          </IonRow>

          <IonActionSheet isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            cssClass='my-custom-class'
            buttons={[{ text: 'From Photos', role: 'destructive', icon: image,
              handler: () => { 
                addImage(CameraSource.Photos);
              }
            }, { text: 'Take Picture', icon: camera,
              handler: () => { 
                addImage(CameraSource.Camera);
              }
            }, { text: 'Cancel', icon: close, role: 'cancel',
              handler: () => {  

              }
            }]} >
          </IonActionSheet>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default GiveSome;
