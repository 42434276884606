
import React from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonLabel, IonRow, IonCol, IonRadioGroup, IonRadio, IonGrid } from '@ionic/react';
import './Pronouns.css';
import { RouteComponentProps } from 'react-router';

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}
interface PronounsProps extends OwnProps, DispatchProps { }

const Pronouns: React.FC<PronounsProps> = ({history}) => {

  const onClickPronouns = (pronouns: string) => {
    const registerDataJson = localStorage.getItem("registerData");
    const pronounsJson = registerDataJson !== null ? JSON.parse(registerDataJson) : '';
    pronounsJson.pronouns= pronouns;
    localStorage.setItem('registerData', JSON.stringify(pronounsJson));
    history.push('/you-like-to-do', {direction: 'none'});
  }

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonHeader>
        <IonToolbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-top extraPad">
      <IonGrid className="profilePic">
        <IonRow className="">
          <IonCol size="12" className="headContent ion-text-center ion-padding-bottom">
            <h2 className="">What Are Your Pronouns?</h2>
          </IonCol>
          <IonCol size="12">
            <IonRadioGroup >
              <IonItem onClick={() => onClickPronouns('she / her / hers')} >
                <IonLabel>she / her / hers</IonLabel>
                <IonRadio value="1" />
              </IonItem>
              <IonItem onClick={() => onClickPronouns('he / him / his')} > 
                <IonLabel>he / him / his</IonLabel>
                <IonRadio value="2" />
              </IonItem>
              <IonItem onClick={() => onClickPronouns('they / them / theirs')}>
                <IonLabel>they / them / theirs</IonLabel>
                <IonRadio value="3" />
              </IonItem>
              <IonItem onClick={() => onClickPronouns('ze / hir/ hirs')}>
                <IonLabel>ze / hir/ hirs</IonLabel>
                <IonRadio value="4" />
              </IonItem>
            </IonRadioGroup>
          </IonCol>
        </IonRow>
      </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Pronouns;
