export interface AppConfig { API_LIVE : string }
export const API: AppConfig = {
    //API_LIVE : "http://192.168.1.72/praise-pwa-react/api/v3/",
    //API_LIVE: "https://live-api.snaps-app.com/api/v3/",
    API_LIVE: "https://beta.snaps-app.com/api/v3/",
    //API_LIVE : "https://camp.thermalcode.com/api/v3/",
};

export const AppLogout = () => {
    localStorage.removeItem('_isLogin');   
    localStorage.removeItem('_userData');
    localStorage.removeItem('listData');
    localStorage.removeItem('comeFrom');
    localStorage.removeItem('_editData');
    localStorage.removeItem('_token');
    localStorage.removeItem('nextPageId');
    localStorage.removeItem('_feedData');
    localStorage.removeItem('registerData');
    localStorage.removeItem('_listData');
};
