import React, { useState, useContext } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonAvatar, IonRow, IonCol, IonBackButton, IonButtons, IonInput, IonButton, IonGrid, IonText, useIonViewWillEnter, IonToast, IonIcon, IonLoading, IonDatetime } from '@ionic/react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import './JoinClass.css';
import { API, AppLogout } from '../../constants';
import { RouteComponentProps, useHistory } from 'react-router';
import { arrowBack } from 'ionicons/icons';
import { UserContext } from "../../App";

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}
interface JoinClassProps extends OwnProps, DispatchProps { }

//const JoinClass: React.FC<JoinClassProps> = ({history}) => {  
  const JoinClass: React.FC = () => {  
  const history = useHistory();
  const user = useContext(UserContext);
  const [email, setEmail] = useState<string>("");
  const [first_name, setFirstName] = useState<string>(""); 
  const [last_name, setLastName] = useState<string>(""); 
  const [phone, setPhone] = useState<string>(""); 
  //const [dob, setDob] = useState<string>(""); 
  const [submitButton, setSubmitButton] = useState<string>("Join Our Class"); 
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [validationMessage, setValidationMessage] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNamelError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
 // const [dobError, setDobError] = useState(false);

  const [editData, setEditData] = useState<any>("");
  const [comeFrom, setComeFrom] = useState<any>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);

  const [verifycode, setVerifyCode] = useState<string>(""); 
  const [checkEmail, setCheckEmail] = useState(false);
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  //setTimeout(() => { setErrorMessage(''); }, 8000);
  localStorage.removeItem('experiaceData');

  useIonViewWillEnter(() => { 
    setEmail('');
      if(localStorage.getItem('_userData') !== null){
        setComeFrom(localStorage.getItem('comeFrom'));
        const editDataJson = localStorage.getItem('_userData');
        const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
        setEditData(data);
        setEmail(data.email);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setPhone(data.phone);
        //setDob(data.dob);
        setSubmitButton('Update');     
      }else{
        setSubmitButton('Join Our Class');
        setEditData(null)
      } 
  });

  const onEmailChnage = (email : any) =>{
    if(email.length >= 0){
      const emailValid = isValidEmailAddress(email);
      if(emailValid){
        setEmailError(false); setErrorMessage(''); setValidationMessage('');
        setEmail(email);
      } else {
        setEmailError(true);
        setEmail(email);
        setValidationMessage('Enter valid eamil address.');  
      }
    }else{ setEmailError(true);
      setValidationMessage('Email is required');  
    } 
  }

  function isValidEmailAddress(address : any) {
    return !! address.match(/.+@.+/);
  }
  
  const onFirstNameChnage = (first_name : any) =>{
    if(first_name.length >= 0){ setFirstNamelError(false);
      setFirstName(first_name)
    }else{ setFirstNamelError(true); } 
  }
  
  const onLastNameChnage = (last_name : any) =>{
    if(last_name.length >= 0){ setLastNameError(false);
      setLastName(last_name)
    }else{ setLastNameError(true); } 
  }

/*   const onDobChnage = (dob : any) =>{
    if(dob.length >= 0){ setDobError(false);
      setDob(dob)
    }else{ setDobError(true); } 
  } */

  const onPhoneChnage = (phone : any) =>{
    if(phone.length >= 0){ 
      setPhoneError(false);
      setPhone(phone);
      /* if(phone.length >= 10){ 
        if(phone.length <===> 10){ 
          setPhoneError(false);
          setPhone(phone);
        }else{  setPhoneError(true);
          setValidationMessage('Enter minimum 10 digits');  
        }
      }else{ setPhoneError(true);
        setValidationMessage('Enter minimum 10 digits');  
      } */
    }else{ 
      setValidationMessage('Phone is required');  
      setPhoneError(true); } 
  }

  const joinClassClick = (e: React.FormEvent) => {
   
    e.preventDefault();
    setFormSubmitted(true);
    const emailValid = isValidEmailAddress(email);
    if(email){
      if(emailValid){
        if(first_name){
          if(last_name){ 
            if(phone){
              //if(dob){
               if(email && first_name && last_name && phone){
                if(!editData){
                   // Validate Email
                   setShowLoading(true);
                    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ email: email, first_name: first_name, last_name: last_name, phone: phone })
                    };
                    fetch(API.API_LIVE + 'signup', requestOptions).then(async response => {
                      const data = await response.json();
                      if(data.status !== false){
  
                        var data1 : any = {id: data.id, email: email, first_name: first_name, last_name: last_name, phone: phone,_token: data.token };
                        localStorage.setItem('registerData', JSON.stringify(data1));
                        //history.push('/profile-photo', {direction: 'none'});
                        setCheckEmail(true);
                        setErrorMessage('');
                        setToastMessage(data.message);
                        setShowToast(true);
  
                      }else{
                        setErrorMessage(data.message);
                        setToastMessage(data.message);
                        setShowToast(true);
                      }
                      setShowLoading(false);
                      setFormSubmitted(false);
                    }).catch(error => {
                      setErrorMessage(error.toString());
                      setFormSubmitted(false);
                    });
                    // Validate Email
                }else{
                   // Edit Data
                    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' ,Authorization: 'Bearer ' + localStorage.getItem('_token') },
                        body: JSON.stringify({ student_id: editData.id, email: email, first_name: first_name, last_name: last_name, phone: phone})
                      };
                      fetch(API.API_LIVE + 'account/update', requestOptions).then(async response => {
                        const data = await response.json();
                        if(data.status !== false){
                          history.goBack();
                          localStorage.setItem('comeFrom', 'edit');
                          setErrorMessage('');
                          setToastMessage(data.message);
                          setShowToast(true);
                        }else{
                          if(data.code === '401'){
                            goLogout(data.message);
                          }else{
                            setShowToast(true);
                            setErrorMessage(data.message);
                          }
                        }
                       setFormSubmitted(false);
                      }).catch(error => {
                        setErrorMessage(error.toString());
                        setFormSubmitted(false);
                      });
                    // Edit Data
                }
              }else{  setErrorMessage('Please enter all credentials');}
             // }else{ setDobError(true); setValidationMessage('DOB is required'); }     
            }else{ setPhoneError(true); setValidationMessage('Phone is required'); }  
          }else{ setLastNameError(true); }
        }else{  setFirstNamelError(true); }
      }else{  setValidationMessage('Enter valid eamil address.'); }
    }else{ setEmailError(true); setValidationMessage('Email is required'); }
    
    
  };

  const onVerifyCodeChnage = (verifycode : any) =>{
    if(verifycode.length >= 0){ setVerifyCodeError(false);
      setVerifyCode(verifycode);
    }else{ setVerifyCodeError(true); } 
  }

  const verifyClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowLoading(true);
    if(localStorage.getItem('registerData') !== null){
      const editDataJson = localStorage.getItem('registerData');
      const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
      const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, code: verifycode, student_id: data.id })
      };

      fetch(API.API_LIVE+'verifycode', requestOptions).then(async response => {
        const data = await response.json();
        if(data.status !== false){
        // setSuccessMessage(data.message);
          setToastMessage(data.message);
          setShowToast(true);
          setErrorMessage('');
          history.push('/profile-photo', {direction: 'none'});
        }else{
          setErrorMessage(data.message);
        }
        setShowLoading(false);
      }).catch(error => {
        setErrorMessage(error.toString());
          
      });

    }else{
      setCheckEmail(false);
    }
    
  };

  const resendOTP =() =>{
   
    if(localStorage.getItem('registerData') !== null){
      setShowLoading(true);
      const editDataJson = localStorage.getItem('registerData');
      const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
      const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify({ email: data.email, student_id: data.id })
      };

      fetch(API.API_LIVE+'resendverificationcode', requestOptions).then(async response => {
        const data = await response.json();
        if(data.status !== false){
          setToastMessage(data.message);
          setShowToast(true);
          setErrorMessage('');
        }else{
          setErrorMessage(data.message);
        }
        setShowLoading(false);
      }).catch(error => {
        setErrorMessage(error.toString());
          
      });
    }else{
      setToastMessage("Somethings went to wrong..");
    }  
  }
  
  const goBackPage = ()=>{ history.goBack(); }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }  
  return (
    <IonPage>
       {editData && <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="dark" >
            {/* <IonBackButton defaultHref="/profile" text="" /> */}
            {comeFrom === 'edittable' && <IonButton onClick={goBackPage}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>}

      <IonHeader>
        <IonToolbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-top">

      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      <IonLoading isOpen={showLoading} backdropDismiss={true}  message="Loading..." onDidDismiss={() => setShowLoading(false)}/>
      <IonGrid className="loginGrid">
       <div className="logBottom"> 
        <IonRow className="ion-margin-bottom">
          <IonCol size="12" className="ion-text-center">
            <IonAvatar className="ion-text-center DNB">
              <img src="/assets/img/logo.svg" alt="Application Logo"/>
            </IonAvatar>
            <h2 className="ion-text-center">
              Get To Know GSB'22
            </h2>
          </IonCol>
        </IonRow>
       
        <IonRow className="home-spacer">
            <IonCol size="12">
            {errorMessage && <IonText > <p className="ion-padding-start dangerMessage">
             {errorMessage} </p>
             </IonText>}

             {!checkEmail && <form noValidate onSubmit={joinClassClick}>
              <IonItem>
                <IonInput name="email" tabIndex={1} type="email" placeholder="Stanford GSB Email" value={email} spellCheck={false} autocapitalize="off" enterkeyhint="next" onIonChange={e => onEmailChnage(e.detail.value!)}
                required></IonInput>
              </IonItem>
              {formSubmitted && emailError && <IonText color="danger">
                {validationMessage && <p className="ion-padding-start dangerMessage"> {validationMessage} </p>}
              </IonText>}

              <IonItem>
                 <IonInput name="first_name" tabIndex={2} type="text" placeholder="First Name" value={first_name} spellCheck={false} autocapitalize="off" enterkeyhint="next" onIonChange={e => onFirstNameChnage(e.detail.value!)} required ></IonInput>
              </IonItem>
              {formSubmitted && firstNameError && <IonText color="danger">
                <p className="ion-padding-start dangerMessage"> First name is required </p>
              </IonText>}

              <IonItem>
                 <IonInput name="last_name" type="text" tabIndex={3} placeholder="Last Name" value={last_name} spellCheck={false} autocapitalize="off" enterkeyhint="next" onIonChange={e => onLastNameChnage(e.detail.value)} required ></IonInput>
              </IonItem>
              {formSubmitted && lastNameError && <IonText color="danger">
                <p className="ion-padding-start dangerMessage"> Last name is required </p>
              </IonText>}

              <IonItem>
                 <IonInput name="phone" tabIndex={4} type="number" placeholder="Phone Number" value={phone} spellCheck={false} autocapitalize="off" enterkeyhint="done" onIonChange={e => onPhoneChnage(e.detail.value!)} inputMode="numeric" pattern="[0-9]*" required ></IonInput>
              </IonItem>
              {formSubmitted && phoneError && <IonText color="danger">
                {validationMessage && <p className="ion-padding-start dangerMessage"> {validationMessage} </p>}
              </IonText>}

              {/* <IonItem>
                 <IonDatetime className="ion-no-padding" name="dob" displayFormat="YYYY-MM-DD" pickerFormat="YYYY-MM-DD" placeholder="DOB" value={dob} onIonChange={e => onDobChnage(e.detail.value!)} ></IonDatetime>
              </IonItem>
              {formSubmitted && dobError && <IonText color="danger">
                <p className="ion-padding-start dangerMessage"> DOB is required </p>
              </IonText>} */}
              
              <IonButton expand="block" type="submit" color="primary" className="ion-text-uppercase ion-margin-vertical">{submitButton}</IonButton>
              </form>}
              {checkEmail && <form noValidate onSubmit={verifyClick}>
                <IonItem>
                  <IonInput name="verifycode" type="number" inputMode="numeric" pattern="[0-9]*"  placeholder="Enter Code" value={verifycode} onIonChange={e => onVerifyCodeChnage(e.detail.value!)}
                  required ></IonInput>
                </IonItem>
                {formSubmitted && verifyCodeError && <IonText color="danger">
                  <p className="ion-padding-start"> Verifcation code is required </p>
                </IonText>}
                <p className="ion-text-center">Didn't recieved code? <a onClick={resendOTP}>Resend OTP</a> </p>
                <IonButton expand="block" color="primary" className="ion-text-uppercase ion-margin-vertical" type="submit" >Verify Code</IonButton>
              </form>}

            </IonCol>
        </IonRow>

       {!editData && <IonRow>
          <IonCol className="ion-text-center" size="12">
          <IonText> <p>Already Signed Up? <Link to="/login">Log In</Link></p></IonText>
          </IonCol>
        </IonRow>}

      </div>
    </IonGrid>
   </IonContent>     
  </IonPage>
        );
};

export default JoinClass;
