import React, { useState, useRef, useEffect, useContext} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, useIonViewWillEnter, IonInfiniteScroll, IonInfiniteScrollContent, IonToast, IonProgressBar, IonRefresher, IonRefresherContent, IonIcon, IonButtons, IonBadge, IonModal, IonItem, IonLabel, IonThumbnail, IonRow, IonCol, IonAvatar, IonList, IonActionSheet, IonTextarea } from '@ionic/react';
import { chevronDownCircleOutline, paperPlane, camera, image, close } from 'ionicons/icons'; 

import './Home.css';
import { API, AppLogout } from '../../constants';
import { RouteComponentProps, useHistory } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';
import { UserContext } from "../../App";
//import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;  

interface DailyObd{ postId: string; fromId: string; isLiked: string; totalLikes: string; name:string; }
interface cardTitle { fromId: number; fromName: string; userPhotoUrl: string; toId: number; toName: string; photoUrl: string;  }
interface cardContent { praise: any; photo: string; }

//const Home: React.FC<HomeProps> = ({history}) => {
  const Home: React.FC = () => {
    
    const history = useHistory();
    const user = useContext(UserContext);
    const contentRef: any = useRef(null);
    const listScrollRef: any = useRef(null);
    
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showActionSheet, setShowActionSheet] = useState(false);

    const [userData, setUserData] = useState<any>("");
    const [pageId, setPageId] = useState<number>(1);
    const [previousCount, setPreviousCount] = useState<number>();
    const [feedData, setFeedData] = useState<any>([]);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(false);
    const [showRow, setShowRow] = useState(true);

    // Add Comments
    const [showModal, setShowModal] = useState(false);
    const [commentsData, setCommentsData] = useState<any>([]);
    const [gifsData, setGifsData] = useState<any>([]);
    const [userComment, setUserComment] = useState('');
    const [feedId, setFeedId] = useState(Number);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [commentImage, setCommentImage] = useState<string>("");
    const [commentImageData, setCommentImageData] = useState<any>("");

    if(localStorage.getItem('_isLogin') !== 'false'){
      const userDataJson = localStorage.getItem('_userData');
      if(localStorage.getItem('_userData') !== null && userData === ''){
        const data = userDataJson !== null ? JSON.parse(userDataJson) : '';
        setUserData(data);
      }
    } 

    useIonViewWillEnter(async() => {
     
      if(localStorage.getItem('from') === 'add'){
        setShowPlaceholder(true);
        localStorage.removeItem('_feedData');
        scrollToTop();
        await fetchData(1); setPageId(1);
        localStorage.removeItem('from');
        setTimeout( async () => {
          setShowPlaceholder(false);
        }, 1000); 
      } else {
        
        if(localStorage.getItem('_feedData') !== null && localStorage.getItem('comeFrom') !== null){
          const feedDataJson = localStorage.getItem('_feedData');
          const data = feedDataJson !== null ? JSON.parse(feedDataJson) : '';
          setFeedData(data);
          setDisableInfiniteScroll(false);
        } else { 
          if(localStorage.getItem('nextPageId') !== null && localStorage.getItem('comeFrom') !== null){
            const feedPageJson = localStorage.getItem('nextPageId');
            const page_json = feedPageJson !== null ? JSON.parse(feedPageJson) : '';
            await fetchData(page_json); setPageId(page_json);
          }else{ 
            scrollToTop();
            localStorage.removeItem('_feedData');
            fetchData(1); setPageId(1);
          }
        }
      }  

      console.log('feedData.length:' + feedData.length);
    });
  // Fetch listing
  
  useEffect(() => {
    return function cleanup() {  }
  });

  async function fetchData(nextPageId: number) {
    localStorage.setItem('nextPageId', JSON.stringify(nextPageId));
    setPageId(nextPageId); 
    setShowLoading(true);  
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token')},
      body: JSON.stringify({ student_id: userData.id, page_id: nextPageId})
    };

    //account/praise
    var temp_timestamp = new Date().getTime();
    const res: Response = await fetch(API.API_LIVE+'public/feeds?time='+temp_timestamp , requestOptions);
    res.json().then(async (res) => {
     
      if(res){ 
        if(res.code ==='401'){
          goLogout(res.message);
        }else{
          
          if(nextPageId === 1){
            setPreviousCount(res.length - 1);
          }else{ setPreviousCount(res.length);}
     
          if(res.length > 0){
            let finalData = [];
            const feedList = feedData;
            if(localStorage.getItem('pullTorefresh') === 'yes'){
              localStorage.removeItem('_feedData');
              finalData = res;
              localStorage.removeItem('pullTorefresh')
            }else{
              finalData = feedList.concat(...res);
            }

            if(localStorage.getItem('_feedData') !== null){
              const feedDataJson = localStorage.getItem('_feedData');
              const dataResult = feedDataJson !== null ? JSON.parse(feedDataJson) : '';
              finalData = dataResult.concat(...res);
              localStorage.setItem('_feedData', JSON.stringify(finalData));
            }else{
              localStorage.setItem('_feedData', JSON.stringify(finalData));
            }
            setFeedData(finalData);
          }else{
            setDisableInfiniteScroll(true);
          }
        }
      }
      setShowLoading(false);
    }).catch(err => {
      setShowLoading(false);
    });
  }

  async function searchNext($event: CustomEvent<void>) {
    if(pageId){
      const nextPageId = pageId + 1;
      setPageId(nextPageId);
      await fetchData(nextPageId);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }
  // Fetch listing

  const searchPage = () => {
    localStorage.removeItem('_listData');
    localStorage.setItem('scrollTop', 'yes');
    localStorage.setItem('comeFrom', 'home');
    history.push('my-class');
    //history.replace('home', {direction: 'back'});
  }

  const birthdayPage = (birthday : any) => {
    localStorage.setItem('listData', JSON.stringify(birthday));
    localStorage.setItem('comeFrom', 'birthday'); 
    history.push('/give-some', {direction: 'back'});
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
      localStorage.setItem('pullTorefresh', 'yes');
      setShowLoading(false);
      setShowRow(false);
      localStorage.removeItem('_feedData');
      fetchData(1); setPageId(1);
      setTimeout(() => {
        scrollToTop();
        setShowRow(true);
        event.detail.complete();
      }, 2000);
  }

  const scrollToTop= () => { contentRef.current.scrollToTop(); };
  
  const scrollToBottom = () => { listScrollRef.current.scrollToBottom(300); };

  /* const refs = commentsData.reduce((acc :any, value: any) => {
   // console.log('value.id:' + value.id);
    acc[value.id] = React.createRef();
    return acc;
  }, {});
 
  const handleClick = (id : any) =>{ console.log('idL'+ id);
    refs[id].current.currentscrollIntoView({ behavior: 'smooth',  block: 'start', });
  } */
  
  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }

  // Add Comments

  const addComments = (feed_id: number, comments: any, gifs: any)=>{
    setShowModal(true);
    setFeedId(feed_id); 
    setUserComment(''); setGifImage(''); setCommentImage('');setCommentImageData('');
    setCommentsData(comments);
    setGifsData(gifs);
  }

  const onAddComments = async (e: React.FormEvent) => {
    e.preventDefault(); 
    //if(userComment){
      var isText : boolean = true;
      if(userComment){
        if(userComment.length > 5000){
          isText = false
          setShowToast(true);
          setToastMessage('Enter max 5000 characters only.');
        }
      }

      if(isText){
        // Add Comments
        setFormSubmitted(true);
        setShowProgressBar(true);
        const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token') },
        body: JSON.stringify({  post_id:feedId, from: userData.id, comment: userComment, photo: commentImageData, gifs: gifId})
        };
        
        const res: Response = await fetch(API.API_LIVE+'praise/post/comment', requestOptions);
        res.json().then(async (res) => {
          if(res.status !== false) {
           /*  setShowToast(true);
            setToastMessage(res.message); */
            setUserComment(''); setCommentImageData(''); setGifId(''); setGifImage('');
            const feedDataJson = localStorage.getItem('_feedData');
            const data = feedDataJson !== null ? JSON.parse(feedDataJson) : '';
            const arr1 = data.filter((d: any ) => d.id === feedId);      
            arr1[0].total_comments = res.total_comments;
            arr1[0].comments = res.comments;

            setFeedData(data);
            setCommentsData(res.comments);
            
            localStorage.setItem('_feedData', JSON.stringify(data));
            onRemovePhoto();
            setTimeout( function(){ scrollToBottom(); },100);
            
          } else {
            setShowToast(true);
            setToastMessage(res.message);
          }
          setShowProgressBar(false);
          setFormSubmitted(false);
        }).catch(err => console.error(err));
      }
      //}else{ setInformationError(true); setValidationMessage('Enter only 500 character'); }
      // Add Comments 
    //}  
  }

  const onClickCommentsLike = async(is_like: any, total_like: any, comment_id: any)=>{

    // Like Comments
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token') }, body: JSON.stringify({ from: userData.id, comment_id: comment_id, post_id:feedId }) };

     setCommntLike('', comment_id);

    const res: Response = await fetch(API.API_LIVE+'praise/post/comment_like_unlike', requestOptions);
    res.json().then(async (res) => {
      if(res.status !== false) {
        setCommntLike(res, comment_id);
      } else {
        setShowToast(true);
        setToastMessage(res.message);
      }
      setShowLoading(false);
      setFormSubmitted(false);
    }).catch(err => console.error(err));
    // Like & Unlike Comments
  }

  function setCommntLike(res : any, comment_id: number){

    const feedDataJson = localStorage.getItem('_feedData');
    const data = feedDataJson !== null ? JSON.parse(feedDataJson) : '';
    const arr1 = data.filter((d: any ) => d.id === feedId);
    const arr2 = arr1[0].comments.filter((d: any ) => d.id === comment_id);
    
    if(res !== ''){
      arr2[0].is_liked = res.is_liked;
      arr2[0].total_likes = res.total_likes;
    }else{
      if(arr2[0].is_liked === 0){ 
        arr2[0].is_liked = 1; 
        arr2[0].total_likes = arr2[0].total_likes + 1;
      } else{ 
        arr2[0].is_liked = 0; 
        arr2[0].total_likes = arr2[0].total_likes - 1;
      }
    }

    setFeedData(data);
    setCommentsData(arr1[0].comments);
    localStorage.setItem('_feedData', JSON.stringify(data));
  } 
  const onBirthdayList = () =>{
    localStorage.setItem('comeFrom', 'birthday-list');
    history.replace('/birthday-list');
  }

  const onClickUserprofile = (student_id : number, name: string)=>{
    const jsonData = {id:student_id, first_name: name, last_name: ''}; 
    localStorage.setItem('listData', JSON.stringify(jsonData));
    localStorage.setItem('comeFrom', 'home');
    history.push('/profile-details', {direction: 'none'});
  }

  const addCommentImage = async (source: CameraSource) => {
    setCommentImage('');
    const image = await Camera.getPhoto({ quality: 90, allowEditing: true, resultType: CameraResultType.Base64, source });
    var temp_timestamp = new Date().getTime();
    const imageName =  'image_'+ temp_timestamp + '.' +image.format;
    const base64Image = 'data:image/'+image.format +';base64,'+ image.base64String;

    setCommentImage(base64Image);
    setCommentImageData(image.base64String);
  }

  const onRemovePhoto = async()=>{
    setCommentImage(''); setGifImage(''); 
    setCommentImageData('');
  }

  const openActionSheet = () =>{ setShowGif(false); setGifId(''); setGifImage(''); }

/* const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const onEmojiClick = (event :any, emojiObject :any) => {
      setChosenEmoji(emojiObject);
  }
  const showHideEmoji = () =>{
    if(showEmoji){ setShowEmoji(false); }else{ setShowEmoji(true); }
  } */

  const [showGif, setShowGif] = useState(false);
  const [gifId, setGifId] = useState<string>("");
  const [gifImage, setGifImage] = useState<string>("");

  const showHideGif = () =>{ if(showGif){ setShowGif(false); }else{ setShowGif(true); } }

  const chooseGifImage = (gif_id:any,gif: any) =>{
    setGifId(gif_id); setGifImage(gif);
    setShowGif(false);
  }  

  const onRemoveGif = async()=>{ setGifImage(''); }

  
  return (
    <IonPage className="homePageNew ">
      <IonHeader translucent>
        {showLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}
        <IonToolbar className="transparent ion-padding-top firstHead head__break">
          <IonTitle className="ion-text-center homeHead ion-no-padding">Give Someone a <br />Shoutout &nbsp; <img src="assets/img/shout.png" alt="Shouting hand"/> </IonTitle>
         {/*  <IonButtons slot="end" ><IonButton className="commentIcon" color="white">
            <IonIcon icon={chatboxEllipses} slot="end" className="ion-no-margin" />
            <IonBadge slot="end" color="primary">5</IonBadge>
          </IonButton></IonButtons> */}
        </IonToolbar>
        <IonToolbar className="transparent">
          <IonButton color="primary" expand="block" shape="round" onClick={searchPage} >Choose a Classmate <i className="material-icons ion-no-margin">arrow_drop_down</i></IonButton>
        </IonToolbar>
      </IonHeader>

      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage} duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      
      <IonContent className="transparent" ref={contentRef} scrollEvents={true}>
      <IonRefresher className="home__refresher" slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing...">
        </IonRefresherContent>
      </IonRefresher>
      {showPlaceholder && <div className="cardSkeleton"></div>}

     {/*  {feedData.map((feed: any, i: number) => {
        return <div  key={`${feed.id}${feed.total_likes}`}>
          {feed.to_name && <IonCard>
            <CardTitleComponent fromId={feed.from_id} fromName={feed.from_name} userPhotoUrl={feed.user_photo_url} toId={feed.to_id} toName={feed.to_name} photoUrl={feed.photo_url} />
            <IonCardContent key={`${feed.id}`}>
                <CardContentComponent praise={feed.praise} photo={feed.photo} />
                <div className="d-flex ion-align-items-center postBottomSection ion-justify-content-between">
                  <LikeComponent postId={feed.id} fromId={userData.id} isLiked={feed.is_liked} totalLikes={feed.total_likes} name={feed.to_name} />
                  {feed.comments.length === 0 && <span onClick={() =>addComments(feed.id, feed.comments)}>Add Comment</span>}
                  {feed.comments.length !== 0 && <span onClick={() =>addComments(feed.id, feed.comments)}>
                    {feed.comments.length} {(feed.comments.length === 1) ? 'Comment' : 'Comments '}</span>}
                </div>
            </IonCardContent>
          </IonCard>}
          {!feed.to_name && <div className="cardSkeleton"></div>}
        </div>
        })} */}

        {showPlaceholder && <div className="cardSkeleton"></div>}

        {/* {feedData.map((feed: any, i: number) => {
        return <IonCard key={`${feed.id}${feed.total_likes}`}>

        <CardTitleComponent fromId={feed.from_id} fromName={feed.from_name} userPhotoUrl={feed.user_photo_url} toId={feed.to_id} toName={feed.to_name} photoUrl={feed.photo_url} />
        <IonCardContent key={`${feed.id}`}>
            <CardContentComponent praise={feed.praise} photo={feed.photo} />
            <LikeComponent postId={feed.id} fromId={userData.id} isLiked={feed.is_liked} totalLikes={feed.total_likes} name={feed.to_name} />
        </IonCardContent>
        </IonCard> })} */}
      
      

        {feedData.length !== 0 && <div> {feedData.map((feed: any, i: any) => {
          return <div  key={`${i}${feed.id}${feed.total_likes}`}>
              {i=== 0 && <div>
              {showRow && <IonRow className="birthday__head">
                <IonCol size="12" className="d-flex ion-align-items-center ion-justify-content-between">
                  <p className="ion-no-margin d-flex align-items-center">
                    {feedData[feed.total_size ? feed.total_size : 0 ].todays.length === 0 ? 'Upcoming Birthdays' : 'Birthdays Today' } &nbsp; 
                     {feedData[feed.total_size ? feed.total_size : 0].todays.length === 0 ? '' : <img alt="Party" src="/assets/img/party__symbol.svg" />}
                  </p>
                  <span onClick={onBirthdayList} className="view__all__btn">View All</span>
                </IonCol> 
              </IonRow>}

              {feedData[feed.total_size ? feed.total_size : 0].todays.length > 0 && <div className="todays__birthday">
                {feedData[feed.total_size ? feed.total_size : 0].todays.map((birthday: any, j: number) => {  
                  return <IonCard key={`${j}`} className="birthday__card">
                    <div className="d-flex ion-align-items-center">
                      <IonAvatar className="birthday__user" onClick={()=> onClickUserprofile(birthday.id, birthday.first_name)}>
                      <img src={(birthday.photo_url) ?  birthday.photo_url : 'assets/img/default.svg'} alt="User Profile"/>
                      </IonAvatar>
                      <h6 className="ion-no-margin b__user__name">{birthday.first_name} {birthday.last_name}</h6>
                    </div>
                    <IonAvatar className="shouting__pan" onClick={() => birthdayPage(birthday)}>
                      <img src='assets/img/birthday__shout.svg' alt="Birthday Shout" />
                    </IonAvatar>
                  </IonCard>})}
                  {feedData[feed.total_size ? feed.total_size : 0].todays.length > 1 && <span className="blank__spacer__dob"></span>}   
              </div>} 
            </div>}

            {i !== feed.total_size && <IonCard>
              <CardTitleComponent fromId={feed.from_id} fromName={feed.from_name} userPhotoUrl={feed.user_photo_url} toId={feed.to_id} toName={feed.to_name} photoUrl={feed.photo_url} />
              <IonCardContent key={`${feed.id}`}>
                  <CardContentComponent praise={feed.praise} photo={feed.photo} />
                  <div className="d-flex ion-align-items-center postBottomSection ion-justify-content-between">
                    <LikeComponent postId={feed.id} fromId={userData.id} isLiked={feed.is_liked} totalLikes={feed.total_likes} name={feed.to_name} />
                  {feed.total_comments === 0 && <span className="comment__span" onClick={() => addComments(feed.id, feed.comments, feedData[feed.total_size ? feed.total_size : 0].gifs)}><img src="assets/img/comment__icon.svg" alt="add commnets icons" />Add Comment</span>}
                  {feed.total_comments !== 0 && <span className="comment__span" onClick={() => addComments(feed.id, feed.comments, feedData[feed.total_size ? feed.total_size : 0].gifs)}> <img src="assets/img/comment__icon.svg" alt="commnets icons" />
                    {feed.total_comments} {(feed.total_comments === 1 || feed.total_comments === 0 ) ? 'Comment' : 'Comments '}</span>}
                </div>
              </IonCardContent>
            </IonCard>} 
          </div>
        })} </div>}
 
        {feedData.length === 0 && <div>
          <div className="cardSkeleton"></div>
          <div className="cardSkeleton"></div>
          <div className="cardSkeleton"></div>
          <div className="cardSkeleton"></div>
        </div>}

        {previousCount === 50 && <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent loadingText="Loading more ..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>}
       
        <IonModal isOpen={showModal} cssClass='commentModal' backdropDismiss={true} swipeToClose={true}  onDidDismiss={() => setShowModal(false)}  mode="ios">

       
          <div className="d-flex ion-align-items-center commentDisplayName">
            <i className="material-icons ion-no-margin" onClick={() => setShowModal(false)}>keyboard_arrow_left</i>
            <h5 className="commentUN ion-no-margin">{commentsData.length} {(commentsData.length === 1 || commentsData.length === 0) ? 'Comment' : 'Comments '}</h5>
          </div>
          {showProgressBar && <IonProgressBar type="indeterminate"></IonProgressBar>}
          <IonContent ref={listScrollRef} scrollEvents={true} className="vh90h">
            <IonList className="commentSection">
              {commentsData.map((comment: any, i: number) => { 
              return <IonItem  key={`${comment.id}${comment.total_likes}`} > {/* ref={refs[comment.id]} */}
                <IonThumbnail slot="start">
                  <img src={(comment.user_photo_url) ? comment.user_photo_url : 'assets/img/default.svg'} alt="Comment User"/> 
                </IonThumbnail>
                <IonLabel>
                  <div className="d-flex ion-align-items-center commentUHead">
                    <h5 className="commentUN">{comment.from_name}</h5>
                    <span className="timeDot"></span>
                    <span className="timeSpan">{comment.posted_at}</span>
                  </div>
                  <p className="commentText">{comment.comment_text}</p>
                  
                  {(comment.photo ) && <IonAvatar slot="start" className="profile border-1-faint">
                    <img alt="Comment Icon" src={comment.photo} />
                  </IonAvatar>}
                  {comment.gif_photo && <img alt="Comment Icon" src={comment.gif_photo} /> }

                  <div className="d-flex ion-align-items-center ion-justify-content-between">
                    <div className="commentActionText">
                      <span onClick={(event) => onClickCommentsLike(comment.is_liked, comment.total_likes, comment.id )} className="ion-margin-end" >Snap</span>
                    {/*   <a href="#" >Reply</a> */}
                    </div>

                    <div className={"d-inline-flex ion-align-items-center commentLikes" + (comment.is_liked === 1 ? ' liked ' : '')}  onClick={(event) => onClickCommentsLike(comment.is_liked, comment.total_likes, comment.id )}> {comment.total_likes} <i className="material-icons" color="primary">
                      <img src={(comment.is_liked === 1 ? '/assets/img/likeDone.svg' : '/assets/img/like.svg')}  alt="dislike "/></i>
                    </div>
                  </div>
                </IonLabel>
            
                {/* <IonList> 
                  {comment.inner_comments.map((inner: any, i: number) => {
                  return <IonItem className="commentReplyItem" key={`${inner.id}${inner.total_likes}`}>
                    <IonThumbnail slot="start">
                      <img src={(inner.user_photo_url) ? inner.user_photo_url : 'assets/img/default.svg'} alt="Comment User"/> 
                    </IonThumbnail>
                    <IonLabel>
                      <div className="d-flex ion-align-items-center commentUHead">
                        <h5 className="commentUN">{inner.from_name}</h5>
                        <span className="timeDot"></span>
                        <span className="timeSpan">{inner.posted_at}</span>
                      </div>
                      <p className="commentText">{inner.comment_text}</p>
                      <div className="d-flex ion-align-items-center ion-justify-content-between">
                        <div className="commentActionText">
                          <a href="#" className="ion-margin-end" >Snap</a>
                          <a href="#" >Reply</a>
                        </div>
                        <div className="d-inline-flex ion-align-items-center commentLikes">
                        {comment.total_likes} <i className="material-icons"><img src='/assets/img/like.svg' alt="dislike " /></i>
                        </div>
                      </div>
                    </IonLabel>
                    </IonItem>})}
                  </IonList> */}
                </IonItem>
              })}
            </IonList>
            <form noValidate onSubmit={onAddComments}>
              {(commentImage || gifImage) && <div className="fixed__image">
                {commentImage && <IonAvatar slot="start" className="profile">
                  <img alt="Comment Icon" src={commentImage} />
                  <i className="material-icons" onClick={onRemovePhoto}>clear</i>
                </IonAvatar>}
                {gifImage && <IonAvatar slot="start" className="profile">
                  <img alt="Comment Icon" src={gifImage} />
                  <i className="material-icons" onClick={onRemoveGif}>clear</i>
                </IonAvatar>}
              </div>}

              <div className="commentFooter">
                <div className="commentInput">
                  {/* <IonInput placeholder="write a comment..." value={userComment} onIonChange={e => setUserComment(e.detail.value!)} required></IonInput> */}
                  <IonTextarea placeholder="write a comment..." value={userComment} onIonChange={e => setUserComment(e.detail.value!)} required></IonTextarea>
                  {!formSubmitted && (commentImage || gifImage || userComment.length !==0) && <IonButton color="primary" type="submit" shape="round" >
                    <IonIcon icon={paperPlane} />
                  </IonButton>}
                </div>

                <div className="commentOptions hide-on-keyboard-open">
                  <IonButtons>
                    <IonButton color="medium" shape="round" disabled={ gifImage ? true : false } onClick={() =>{setShowActionSheet(true); openActionSheet()}}> <i className="material-icons">camera_alt</i>
                    </IonButton>
                  </IonButtons>
                  
                  <IonButtons>
                    <IonButton color="medium"  disabled={ commentImage ? true : false } onClick={showHideGif} shape="round"> <i className="material-icons">gif</i>
                    </IonButton>
                  </IonButtons>
                  
                  {/* <IonButtons onClick={showHideEmoji}>
                    <IonButton color="medium" shape="round" >
                        <i className="material-icons">insert_emoticon</i>
                      </IonButton>
                  </IonButtons> */}
                </div>
              </div>
            </form> 
          </IonContent>
          

          {showGif && <div className={"gif__space" + (showGif === true ? ' activeted__gif ' : '')} >
            <IonRow className="gif__flex">
            {gifsData.map((gif: any, i: number) => {
              return <IonCol className="gif__height" key={`${gif.name}${gif.url}`} onClick={() =>chooseGifImage(gif.name, gif.url)} size="4">
                <img src={gif.url} alt="usergif icons" ></img>
              </IonCol>
              })} 
              </IonRow>
              
          </div>}
     
          {/* {showEmoji && <div> 
            <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} skinTone={SKIN_TONE_MEDIUM_DARK} groupNames={{smileys_people:"PEOPLE"}}/>
            { chosenEmoji && <EmojiData chosenEmoji={chosenEmoji}/>}
          </div>} */}
          {/* <p>This is modal content</p>
          <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton> */}
     
        </IonModal>

        <IonActionSheet isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)}
            cssClass='image__picker__home'
            buttons={[{ text: 'From Photos', role: 'destructive', icon: image,
              handler: () => { 
                addCommentImage(CameraSource.Photos);
              }
            }, { text: 'Take Picture', icon: camera,
              handler: () => { 
                addCommentImage(CameraSource.Camera);
              }
            }, { text: 'Cancel', icon: close, role: 'cancel',
              handler: () => {  

              }
            }]} >
          </IonActionSheet> 
      </IonContent>
    </IonPage>
  );
};


/* const EmojiData = (chosenEmoji : any) => (
  <div>
    <strong>Unified:</strong> {chosenEmoji.unified}<br/>
    <strong>Names:</strong> {chosenEmoji.names.join(', ')}<br/>
    <strong>Symbol:</strong> {chosenEmoji.emoji}<br/>
    <strong>ActiveSkinTone:</strong> {chosenEmoji.activeSkinTone}
  </div>
); */

export const CardTitleComponent: React.FC<cardTitle> = ({ fromId, fromName, userPhotoUrl, toId, toName,photoUrl }) => {

  const history = useHistory();
  const onClickUserprofile = (student_id : number, name: string)=>{
    const jsonData = {id:student_id, first_name: name, last_name: ''}; 
    localStorage.setItem('listData', JSON.stringify(jsonData));
    localStorage.setItem('comeFrom', 'home');
    history.push('/profile-details', {direction: 'none'});
  }
  
  return (
  <>  <IonCardHeader>
        <IonCardTitle>
          <div className="userImages">
            <span className="secondUser" onClick={()=> onClickUserprofile(fromId, fromName)}>
              <img src={(userPhotoUrl) ?   userPhotoUrl : 'assets/img/default.svg'} alt="Second User"/> 
            </span>
            <span className="primaryUser" onClick={()=> onClickUserprofile(toId, toName)}>
                 <img src={(photoUrl) ?   photoUrl : 'assets/img/default.svg'} alt="Primary User"/>
            </span>
          </div>
          <div className="userNames"> 
          <div className="font-semiBold userShoutName">
            <h4 className="head-text-color" onClick={()=> onClickUserprofile(fromId, fromName)}> {fromName} </h4> &nbsp;<span className="subText font-regular faint-gray-color">shouted out</span>
          </div>
            <h4 className="primary-color font-semiBold" onClick={()=> onClickUserprofile(toId, toName)} >{toName}</h4>
          </div>
        </IonCardTitle>
      </IonCardHeader>
  </>
  )
}

export const CardContentComponent: React.FC<cardContent> = ({ praise, photo }) => {
  return (
  <>  
      <p className="dark-color font-regular whiteSpace">{praise} </p>
      {photo && <div className="imgDiv">
        <img src={(photo === '') ? 'assets/img/defaultpost.jpg' :   photo } alt="Feed Praise" /> </div>}
   </>
  )
}

export const LikeComponent: React.FC<DailyObd> = ({ postId,fromId,isLiked,totalLikes, name}) => {
  const [is_liked, setIsLiked] = useState<any>(isLiked);
  const [total_likes, setTOtalLike] = useState<any>(totalLikes);
  
  const onClickLike = async(changeliked: any, totalLikesimg: any)=>{
    
    if(changeliked === 0){
      setIsLiked(1);
      setTOtalLike(totalLikesimg + 1);
    }else{
      setIsLiked(0);
      setTOtalLike(totalLikesimg - 1);
    }

  const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token') },
        body: JSON.stringify({ from:fromId, post_id: postId })
      };
      const res: Response = await fetch(API.API_LIVE+ 'praise/post_like_unlike', requestOptions);
      res.json().then(async (res) => { 
        if(res.status !== false) {
          setIsLiked(res.is_liked);
          setTOtalLike(res.total_likes);
          const feedDataJson = localStorage.getItem('_feedData');
          const data = feedDataJson !== null ? JSON.parse(feedDataJson) : '';
          const arr2 = data.filter((d: any ) => d.id === postId);
          arr2[0].is_liked= res.is_liked;
          arr2[0].total_likes= res.total_likes;
          localStorage.setItem('_feedData', JSON.stringify(data));
        }
      }).catch(err => console.error(err)); 
    }

  return (
  <>
    <div className={"d-flex ion-align-items-center likedUser max60" + (is_liked === 1 ? ' liked ' : '')}>
      <i onClick={(event) => onClickLike(is_liked, total_likes)} className="material-icons" color="primary">
        <img src={(is_liked === 1 ? '/assets/img/likeDone.svg' : '/assets/img/like.svg')}  alt="dislike "/></i>
        <p>{total_likes} people snapped for {name}</p>
    </div>
  </>
  )
}

export default React.memo(Home);
