import React, { useState, useContext } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonAvatar, IonLabel, IonRow, IonCol, IonGrid, IonItemDivider, IonBackButton, IonButtons, useIonViewDidEnter, IonProgressBar, IonTitle, useIonViewDidLeave, IonToast, IonButton } from '@ionic/react';
import '../profile/Profile.css';
import { API, AppLogout } from '../../constants';
import { UserContext } from "../../App";
import { useHistory } from 'react-router';
import { personAdd } from 'ionicons/icons';

//import { Contact, ContactField, ContactName } from '@ionic-native/contacts';
/* import { PermissionsAndroid } from 'react-native'; 
import Contacts from "react-native-contacts"; */
/* import { Contact, ContactField, ContactName } from '@ionic-native/contacts'; */
import { Capacitor, Plugins } from '@capacitor/core';

//const  { Contacts } = Plugins;

interface Experience { id: number; institute_name: any; role_of_study: any; }

//const ProfileDetails: React.FC<ProfileDetailsProps> = ({history}) => {
  
  const ProfileDetails: React.FC = () => {
    
  const history = useHistory();
  const user = useContext(UserContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const [userData, setUserData] = useState<any>(""); 
  const [comeFrom, setComeFrom] = useState<any>("");
  const [experienceData, setExperienceData] = useState<Experience[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    if(localStorage.getItem('_isLogin') !== 'false'){
      const listDataJson = localStorage.getItem('listData');
      const userDataJson = localStorage.getItem('_userData');
      if(localStorage.getItem('listData') !== null && userData === ''){
        const data = listDataJson !== null ? JSON.parse(listDataJson) : '';
        const data1 = userDataJson !== null ? JSON.parse(userDataJson) : '';
        setComeFrom(localStorage.getItem('comeFrom'));
        setShowLoading(true);
        fetchData(data.id, data1.id);
      }
    } 
  });

  useIonViewDidLeave(() => { 
    setShowLoading(false);
    setUserData([]);
    setExperienceData([]);
  });  

  async function fetchData(studentId : number, loginId: any) {
    const res: Response = await fetch(API.API_LIVE+'profile?profile_id='+ studentId +'&is_profile=0&loginId=' + loginId, { method:'GET', headers: {'Authorization': 'Bearer ' + localStorage.getItem('_token')}});
    res.json().then(async (data) => {
      if (data) {
        if(data.code === '401'){
          goLogout(data.message);
        }else{
          setUserData(data);
          setExperienceData(data.experiences);
        }
      }
      setShowLoading(false);
    }).catch(err => {console.error(err); setShowLoading(false);});
  }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }  
  
  const onAddContact = ()=>{

    // console.log('onAddContact');
    /*  PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.WRITE_CONTACTS, {
        'title': 'Contacts',
        'message': 'This app would like to view your contacts.',
        'buttonPositive': 'Please accept bare mortal'
      }
    ).then(() => { */

      /* const listDataJson = localStorage.getItem('listData');
      const data = listDataJson !== null ? JSON.parse(listDataJson) : '';
      console.log('onAddContact:' + JSON.stringify(data));

      var newPerson :any = {
        emailAddresses: [{
          label: "work",
          email: "mrniet@example.com",
        }],
        familyName: "Nietzsche",
        givenName: "Friedrich",
      }
       
      Contacts.addContact(newPerson, (err) => {
        if (err) {
          console.log('2');
          console.log('err' + JSON.stringify(err));
        }// save successful
      }) */
    //})

    /* Contacts.pickContact().then((response: Contact) => { 
        console.log(response);
        console.log('response:'+ JSON.stringify(response));
    }); */

    // const contact = Contacts.create();
    // const contact: Contact = Contacts.create();
    // const contacts = new Contacts();

/*     const contact = {
      [Contacts.Fields.FirstName]: 'Bird',
      [Contacts.Fields.LastName]: 'Man',
    };
    const contactId = await Contacts.addContactAsync(contact); */

   /*  const contact = {[Contacts.Fields.FirstName]: 'Bird', [Contacts.Fields.LastName]: 'Man' };

    let contact = Contacts.addContact(contact);

    contact.name = new ContactName( 'null', 'Smith', 'John');
    contact.phoneNumbers = [new ContactField('mobile', '6471234567')];
    contact.save().then(() => {
      console.log('Contact' + JSON.stringify(contact));
      console.log('Contact saved!', contact)
    },(error: any) => console.error('Error saving contact.', error)
    ); */
  }

/* contactId: string;
  displayName?: string;
  phoneNumbers: string[];
  emails: string[];
  organizationName?: string;
  organizationRole?: string;
  birthday?: string; */

  return (
    <IonPage>
       {showLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}
       {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="primary">

            {comeFrom === 'home' && <IonBackButton color="primary" defaultHref="/home" text="" />}
            {/* {comeFrom === 'home' && Capacitor.platform === 'ios' && <IonBackButton color="primary" mode="ios" defaultHref="/home" text="" />} */}
            
            {comeFrom !== 'home' && comeFrom !== 'birthday-list' && <IonBackButton color="primary" defaultHref="/my-class" text="" />}
            {/* {comeFrom !== 'home' && comeFrom !== 'birthday-list' && Capacitor.platform ==='ios' && <IonBackButton color="primary" mode="ios" defaultHref="/my-class" text="" />} */}

            {comeFrom === 'birthday-list' &&  <IonBackButton color="primary"  defaultHref="/birthday-list" text="" />}
            {/* {comeFrom === 'birthday-list' && Capacitor.platform ==='ios' && <IonBackButton color="primary" mode="ios" defaultHref="/birthday-list" text="" />} */}
            
          </IonButtons>
 
          <IonTitle className="headTitle">User Profile</IonTitle>
      {/*   <IonButtons slot="end">
            <IonButton fill="clear" color="primary" className="ion-text-capitalize" onClick={onAddContact}>Add Contact</IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      <IonContent className="profile__ui__changes ">
       <IonGrid className="prifileGrid">

        {!userData && <div className="d-flex ion-align-items-center ion-margin-bottom">
          <div className="userSkel skeleton ion-margin-end"></div>
          <div className="userNameSkel skeleton"></div>
        </div>}

        {userData && <IonItem className="profileItem">
          <IonAvatar slot="start" className="profile">
            <img src={(userData.photo_url) ?   userData.photo_url : 'assets/img/default.svg'} alt="User Profile" />
          </IonAvatar>
          <IonLabel>
            {userData.first_name && <h4>{userData.first_name} {userData.last_name} </h4>}
            {userData.pronouns && <p>{userData.pronouns} </p>}
          </IonLabel>
        </IonItem>}

        {userData && <h3 className="ion-text-center ion-margin-vertical">Total Profile Views: {userData.total_views}</h3>}

        <IonItemDivider color="light" className="ion-margin-vertical"></IonItemDivider>
        
          {!userData && <IonRow className="ion-margin-top">
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton w-50 mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton w-50 mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12">
              <div className="buttonSkel skeleton"></div>
            </IonCol>
          </IonRow>}

          {userData && <IonRow>

            <IonCol size="12" className="headContent">
              <h5 className="d-a ion-justify-content-between">
                <span className="ion-no-margin d-a">
                  <i className="material-icons">thumb_up</i>
                  Bio / Interests
                  </span> 
              </h5>
              {userData.bio && <p className="whiteSpace line16">{userData.bio} </p>}
            </IonCol>

            <IonCol size="12" className="headContent">
              <h5 className="d-a ion-justify-content-between">
                <span className="ion-no-margin d-a">
                  <i className="material-icons">stars</i>
                  Experience
                  </span> 
              </h5>
              {experienceData.length !== 0 && <div>
                {experienceData.map((experience: any, i: number) => {
                  return <div className="exp__details" key={`${i}`}> 
                    <h6>{experience.institute_name}</h6>
                    <span>{experience.role_of_study}</span>
                  </div>
                })} </div> }
            </IonCol>

              
              <IonCol size="12" className="headContent">
              <h5 className="d-a ion-justify-content-between">
                <span className="ion-no-margin d-a">
                  <i className="material-icons">
                <img src="/assets/img/favorite.svg" alt="Favorite" /></i>
                 Favorite Accomplishment?
                </span>
              </h5> 
                {userData.favorite && <p className="whiteSpace line16">{userData.favorite}</p>}
              </IonCol>
            
               
              <IonCol size="12" className="headContent">
              <h5 className="d-a ion-justify-content-between">
                <span className="ion-no-margin d-a">
                  <i className="material-icons">favorite</i>
                  Relationship Status
                  </span> 
              </h5>
                {userData.rel_status === 1 && <p className="whiteSpace line16">Single</p>}
                {userData.rel_status === 2 && <p className="whiteSpace line16">In A Relationship</p>}
              </IonCol>
              <IonCol size="12" className="headContent headTT">
                <h5 className="d-a ion-justify-content-between">
                  <span className="ion-no-margin d-a">
                    <i className="material-icons">cake</i> Birthday
                  </span>
                </h5>
                {userData.dob && <p className="whiteSpace line16">{userData.dob} </p>}
              </IonCol>
          </IonRow>}

        </IonGrid>
      </IonContent> 
    </IonPage>
  );
};

export default ProfileDetails;