
import React, { useState, useContext } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonRow, IonCol, IonButton, IonGrid, IonTextarea, IonText, IonButtons, IonBackButton, IonToast, IonLoading, useIonViewWillEnter, IonIcon } from '@ionic/react';
import './YouLike.css';
import { API, AppLogout } from '../../constants';
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack } from 'ionicons/icons';
import { UserContext } from "../../App";

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}

interface YouLikeProps extends OwnProps, DispatchProps { }

//const YouLike: React.FC<YouLikeProps> = ({history}) => {
const YouLike: React.FC = () => {  
  const history = useHistory();
  const user = useContext(UserContext);
  const [likeText, setLikeText] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [likeTextError, setLikeTextError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [comeFrom, setComeFrom] = useState<any>("");
  const [showLoading, setShowLoading] = useState(false);
  const [submitButton, setSubmitButton] = useState<string>("Next");
  const [editData, setEditData] = useState<any>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);  

  setTimeout(() => { setErrorMessage(''); }, 8000);


  useIonViewWillEnter(() => {
    if(localStorage.getItem('_userData') !== null){
        setComeFrom(localStorage.getItem('comeFrom'));
        const editDataJson = localStorage.getItem('_userData');
        const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
        setEditData(data);
        setLikeText(data.bio);
        setSubmitButton('Update');     
    }else{
      setSubmitButton('Next');
      setEditData(null)
    } 
  });

  const onLikeTextChnage = (likeText : any) =>{
    if(likeText.length >= 0){
      
      //if(likeText.length >= 140){
        setLikeTextError(false); setValidationMessage('');
        setLikeText(likeText);
      /* }else{
        setLikeTextError(true);
        setLikeText(likeText);
        setValidationMessage('Enter min 140 character');
      } */
    }else{ setLikeTextError(true); setValidationMessage('Like text is required'); } 
  }

  const onYouLikeClick = async (e: React.FormEvent) => {
      e.preventDefault();
      setFormSubmitted(true);
     // if(!likeText) { setLikeTextError(true); }else{ setLikeTextError(false); }

     // if(likeText){
        //if(likeText.length <= 140){
          if(!editData){
            // Register Data
            const registerDataJson = localStorage.getItem("registerData");
            const likeTextJson = registerDataJson !== null ? JSON.parse(registerDataJson) : '';
            likeTextJson.bio= likeText;
            localStorage.setItem('registerData', JSON.stringify(likeTextJson));
            history.push('/favorite', {direction: 'none'});
            // Register Data
          }else{
            // Edit Data
            setShowLoading(true);
            const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json',Authorization: 'Bearer ' + localStorage.getItem('_token') },
              body: JSON.stringify({ student_id: editData.id, bio: likeText})
            };
            fetch(API.API_LIVE + 'account/update_bio', requestOptions).then(async response => {
              const data = await response.json();
              if(data.status !== false){
                localStorage.setItem('comeFrom', 'edit');
                history.goBack();
                setErrorMessage('');
                setToastMessage(data.message);
                setShowToast(true);
              }else{
                if(data.code === '401'){
                  goLogout(data.message);
                }else{
                  setShowToast(true);
                  setErrorMessage(data.message);
                }
              }  
            setShowLoading(false);
            }).catch(error => {
              setErrorMessage(error.toString());
              setShowLoading(false);
                
            });
            // Edit Data
          }

        /* } else{ setLikeTextError(true);
          setValidationMessage('Enter min 140 character');
        } */
      /* }else{
        setLikeTextError(true); setValidationMessage('Like text is required');
      } */
  }

  const goBackPage = ()=>{ history.goBack(); }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }
  return (
    <IonPage>
     {editData && <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="dark">
             {/* <IonBackButton defaultHref="/profile" text="" /> */}
             {comeFrom === 'edittable' && <IonButton color="primary" onClick={goBackPage}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>}

      <IonHeader>
        <IonToolbar>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-top">
      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
      <IonLoading isOpen={showLoading} backdropDismiss={true}  message="Loading..." onDidDismiss={()=> setShowLoading(false)}/>
      <IonGrid className="profilePic">
        <form noValidate onSubmit={onYouLikeClick}>
          <IonRow className="ion-justify-content-between profileContent">
            <IonCol size="12" className="headContent ion-text-center">
              <h2 className="">What Do You Like to Do?</h2>
              <p>Favorite Hobbies, Interests, Idiosyncracies</p>
              {errorMessage && <IonText color="danger"> <p className="ion-padding-start dangerMessage">
              {errorMessage} </p> </IonText>}
              <IonItem className="ion-margin-top textItem">
                <IonTextarea className="youlikeText" name="likeText" placeholder="What hobbies do you enjoy outside of work?                          
                What activities are you looking to do with the rest of our class?                                                                                                
                What are you interested in pursuing post-graduation?" 
                value={likeText} spellCheck={false} 
                autocapitalize="off" 
                onIonChange={e => onLikeTextChnage(e.detail.value!)}
                required ></IonTextarea>

              </IonItem>
              {formSubmitted && likeTextError && <IonText color="danger">
              {validationMessage && <p className="ion-padding-start ion-text-left dangerMessage"> {validationMessage}</p>}

              </IonText>}
            </IonCol>

            <IonCol size="12">
            {!showLoading && <IonButton color="primary" type="submit" expand="block">{submitButton}</IonButton>}
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default YouLike;
