import React, { useContext, useState, useEffect } from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonAvatar, IonLabel, IonRow, IonCol, IonButton, IonGrid, IonItemDivider, IonButtons, useIonViewDidEnter, IonProgressBar, IonText, useIonViewDidLeave, IonActionSheet, IonLoading, IonToast, IonIcon } from '@ionic/react';
import { camera, image, close, refresh } from 'ionicons/icons';

import './Profile.css';
import { API, AppLogout } from '../../constants';
import { UserContext } from "../../App";
import { RouteComponentProps, useHistory } from 'react-router';
/* import { ImageCrop } from 'capacitor-image-crop'; */
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
const { Camera } = Plugins;


interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}
interface ProfileProps extends OwnProps, DispatchProps { }

interface Experience {
  id: number;
  institute_name: any;
  role_of_study: any;
}

/* const Profile: React.FC<ProfileProps> = ({history}) => { */
  
  const Profile: React.FC = () => {

    /* const crop = new ImageCrop(); */
    const history = useHistory();
    const user = useContext(UserContext);
    const [showActionSheet, setShowActionSheet] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");

    const [userData, setUserData] = useState<any>(""); 
    const [photoUrl, setPhotoUrl] = useState<any>(""); 
    const [showLoading, setShowLoading] = useState(false);
    const [experienceData, setExperienceData] = useState<Experience[]>([]);

    const [errorMessage, setErrorMessage] = useState<string>("");
    // const [successMessage, setSuccessMessage] = useState<string>("");

    setTimeout(() => { setErrorMessage('');  }, 5000);

    useIonViewDidEnter(() => {
      if(localStorage.getItem('_isLogin') !== 'false'){
        const userDataJson = localStorage.getItem('_userData');
        if(localStorage.getItem('_userData') !== null && userData === ''){
          const data = userDataJson !== null ? JSON.parse(userDataJson) : '';

          setUserData(data);
          setExperienceData(data.experiences);
          var imageFullpath =   data.photo_url; 
          setPhotoUrl(imageFullpath);

          //if(localStorage.getItem('comeFrom') === 'edit'){
            fetchData(data.id);
          //} 
        }
      }
    });
    
    useIonViewDidLeave(()=>{
      setUserData([]);  setErrorMessage(''); setShowLoading(false); setExperienceData([]);
    });

    async function fetchData(studentId : number) {
     // setShowLoading(true);
      const res: Response = await fetch(API.API_LIVE+'profile?profile_id='+ studentId +'&is_profile=1&loginId=' + studentId , { method:'GET', headers: {'Authorization': 'Bearer ' + localStorage.getItem('_token')}});
      res.json().then((data) => {
        if (data) {
          if(data.code === '401'){
            onLogout(data.message);
          }else{
            setUserData(data);
            setExperienceData(data.experiences);
            var imageFullpath =   data.photo_url; 
            setPhotoUrl(imageFullpath);
            localStorage.setItem('_userData', JSON.stringify(data));
          }
        }
       // setShowLoading(false);
      }).catch(err => {console.error(err); //setShowLoading(false);
      });
    }

  const onLogout = (message: any) => {
    AppLogout();
    setShowToast(true);
    setToastMessage(message);
    user.setIsLoggedIn(false);
    history.replace('/', {direction: 'back'});
  } 

  const addImage = async (source: CameraSource) => {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 300,
        height: 300,
        saveToGallery: false, 
        allowEditing: true,
        correctOrientation : true,
        resultType: CameraResultType.Base64,
        source
      });

    const base64Image = 'data:image/'+image.format +';base64,'+ image.base64String;
    uploadPicture(base64Image, image );
    setPhotoUrl(base64Image);
  }

  const uploadPicture = async (base64Image : any, image: any) =>{
    if(base64Image){
      const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/octet-stream', Authorization: 'Bearer ' + localStorage.getItem('_token') },
        body: JSON.stringify({photo: String(image.base64String), format: image.format, student_id:userData.id, is_profile:1 }) 
      };
      const res: Response = await fetch(API.API_LIVE+'account/upload_image', requestOptions);
      res.json().then(async (res) => {
        if(res.status === true){
          var imageFullpath =   res.url; 

          const userDataJson1 = localStorage.getItem('_userData');
          const dataJson = userDataJson1 !== null ? JSON.parse(userDataJson1) : '';
          dataJson.photo_url= res.url;
          localStorage.setItem('_userData', JSON.stringify(dataJson));
          
          setPhotoUrl(imageFullpath);
          //setSuccessMessage(res.message);
          setToastMessage(res.message);
          setShowToast(true);

        }else{
          setErrorMessage('Image not updated.');
          setPhotoUrl('');
        }
      }).catch(err => console.error(err)); 
    }else{
      setShowToast(true);
      setToastMessage('Please choose image');
    }
  }

  const onEditProfile = ()=>{ onPage('join-class-edit'); } 
  const onEditBio = ()=>{ onPage('you-like-to-do-edit'); } 
  const onEditExperiance = ()=>{ onPage('before-gsb-edit'); } 
  const onEditFavorite = ()=>{ onPage('favorite-edit'); } 
  const onEditRelationShip = ()=>{ onPage('relationship-status-edit'); } 
  const onEditBirthday = ()=>{ onPage('birthday-edit'); } 

  function onPage(pageName: string){
    localStorage.setItem('comeFrom', 'edittable');
    history.push(pageName, {direction:'back'});
  
  }

  return (
    <IonPage>
      {showLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}
      {/* <IonLoading isOpen={showLoading} backdropDismiss={true}  message="Loading..." onDidDismiss={()=> setShowLoading(false)}/> */}
      
      <IonHeader>
        <IonToolbar>
        {/* {userData.first_name && <IonTitle className="headTitle pad-x-15 ion-text-left">{userData.first_name} {userData.last_name} </IonTitle>} */}
        <IonTitle className="headTitle pad-x-15 ion-text-left"> Profile </IonTitle>
         {/*  <IonButtons slot="end" onClick={() => fetchData(userData.id)}>
            <IonButton slot="icon-only" shape="round" color="medium">
              <IonIcon icon={refresh}  />
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="profilePage profile__ui__changes">
      {toastMessage && <IonToast color="dark"  isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
        <IonGrid className="prifileGrid">

          {!userData && <div className="d-flex ion-align-items-center ion-margin-bottom">
            <div className="userSkel skeleton ion-margin-end"></div>
            <div className="userNameSkel skeleton"></div>
          </div>}

          {userData && <IonItem className="profileItem">
            <IonAvatar slot="start" className="profile" onClick={() => setShowActionSheet(true)}>
              {photoUrl && <img alt="Profile Icon" src={photoUrl} /> }
              <i className="material-icons">add_circle</i>
            </IonAvatar>
            <IonLabel className="ion-text-capitalize">
              {userData.first_name && <h4>{userData.first_name} {userData.last_name} </h4>}
              {userData.pronouns && <p>{userData.pronouns} </p>}
            </IonLabel>
            <IonButtons>
              <IonButton size="small" color="medium" fill="clear"  onClick={onEditProfile}>
                <i className="material-icons ion-no-margin">create</i>
            </IonButton>
              </IonButtons>
          </IonItem>}
          
          {userData && <h3 className="ion-text-center ion-margin-vertical">Total Profile Views: {userData.total_views}</h3>}

          <IonActionSheet isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            cssClass='my-custom-class'
            buttons={[{ text: 'From Photos', role: 'destructive', icon: image,
              handler: () => { 
                addImage(CameraSource.Photos);
              }
            }, { text: 'Take Picture', icon: camera,
              handler: () => { 
                addImage(CameraSource.Camera);
              }
            }, { text: 'Cancel', icon: close, role: 'cancel',
              handler: () => {  
                
              }
            }]} >
          </IonActionSheet>

        <IonItemDivider color="light" className="ion-margin-top"></IonItemDivider>
          {errorMessage && <IonText color="danger"> <p className="ion-padding-start dangerMessage">
          {errorMessage} </p> </IonText>}

          {/* {successMessage && <IonText color="success"> <p className="ion-padding-start successMessage">
          {successMessage} </p> </IonText>} */}

          {!userData && <IonRow className="ion-margin-top">
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton w-50 mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12" className="ion-margin-vertical">
              <div className="d-flex ion-align-items-center">
                <div className="IconSkel skeleton ion-margin-end"></div>
                <div className="IconNameSkel skeleton mb-10"></div>
              </div>
              <div className="d-row ion-align-items-center ion-justify-content-end paraSkel">
                <div className="IconNameSkel skeleton mb-10"></div>
                <div className="IconNameSkel skeleton w-50 mb-10"></div>
              </div>
            </IonCol>
            <IonCol size="12">
              <div className="buttonSkel skeleton"></div>
            </IonCol>
          </IonRow>}

          {userData && <IonRow>
            <IonCol size="12" className="headContent headTT">
              <h5 className="d-a ion-justify-content-between"><span className="ion-no-margin d-a"><i className="material-icons">thumb_up</i> Bio / Interests </span>
                <IonButtons>
                  <IonButton size="small" onClick={onEditBio} color="medium" fill="clear" slot="icon-only"><i className="material-icons ion-no-margin">create</i></IonButton></IonButtons>
              </h5> 
              {userData.bio && <p className="whiteSpace line16">{userData.bio} </p>}
            </IonCol>
            <IonCol size="12" className="headContent headTT">
              <h5 className="d-a ion-justify-content-between"><span className="ion-no-margin d-a"><i className="material-icons">stars</i> Experience </span>
                <IonButtons>
                  <IonButton size="small" color="medium" fill="clear" slot="icon-only" onClick={onEditExperiance}><i className="material-icons ion-no-margin">create</i></IonButton>
                </IonButtons>
              </h5>
              {experienceData.length !== 0 && <div>
                {experienceData.map((experience: any, i: number) => {
                  return <div className="exp__details" key={`${i}`}> 
                    <h6>{experience.institute_name}</h6>
                    <span>{experience.role_of_study}</span>
                  </div>
                })} </div> }
            </IonCol>

            <IonCol size="12" className="headContent headTT">
              <h5 className="d-a ion-justify-content-between"><span className="ion-no-margin d-a"><i className="material-icons"><img src="/assets/img/favorite.svg" alt="Favorite" /></i> Favorite Accomplishment?</span>
              <IonButtons>
                    <IonButton size="small" color="medium" fill="clear" slot="icon-only" onClick={onEditFavorite}><i className="material-icons ion-no-margin">create</i></IonButton>
              </IonButtons>
            </h5>   
              {userData.favorite && <p className=" whiteSpace line16">{userData.favorite}</p>}
              {/* {!userData.favorite && <p> No any Favorite Accomplishment.</p>} */}
            </IonCol>

            <IonCol size="12" className="headContent headTT">
              <h5 className="d-a ion-justify-content-between"><span className="ion-no-margin d-a"><i className="material-icons">favorite</i> Relationship Status</span>
              <IonButtons>
                <IonButton size="small" color="medium" fill="clear" slot="icon-only" onClick={onEditRelationShip}><i className="material-icons ion-no-margin">create</i></IonButton>
              </IonButtons>
            </h5>   
              {userData.rel_status === 1 && <p className="whiteSpace line16">Single</p>}
              {userData.rel_status === 2 && <p className="whiteSpace line16">In A Relationship</p>}
            </IonCol>
            <IonCol size="12" className="headContent headTT">
              <h5 className="d-a ion-justify-content-between"><span className="ion-no-margin d-a"><i className="material-icons">cake</i> Birthday </span>
                <IonButtons>
                    <IonButton size="small" color="medium" fill="clear" slot="icon-only" onClick={onEditBirthday}><i className="material-icons ion-no-margin">create</i></IonButton>
                </IonButtons>
              </h5>
              {userData.dob && <p className="whiteSpace line16">{userData.dob} </p>}
            </IonCol>
          </IonRow>}

          {userData && <IonButton expand="block" onClick={() =>onLogout('Logout Successfully.')}>Logout</IonButton>}

        </IonGrid>
      </IonContent> 
    </IonPage>
  );
};

/*   const ExperianceItem: React.FC<{ experience: Experience }> = ({ experience }) => {
    return (
      <IonItem>
        <span>{experience.role_of_study}</span>
        <h6>{experience.institute_name}</h6>
      </IonItem>
    );
  } */

export default Profile;
