
import React, { useState, useContext } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonLabel, IonRow, IonCol, IonButton, IonGrid, IonRadioGroup, IonRadio, IonText, IonToast, IonButtons, IonBackButton, useIonViewWillEnter, IonLoading, IonIcon} from '@ionic/react';
import './RelationshipStatus.css';
import { RouteComponentProps, useHistory } from 'react-router';
import { API, AppLogout } from '../../constants';
import { UserContext } from "../../App";
import { arrowBack } from 'ionicons/icons';

interface DispatchProps { }
interface OwnProps extends RouteComponentProps {}

interface RelationshipStatusProps extends OwnProps, DispatchProps { }

/* const RelationshipStatus: React.FC<RelationshipStatusProps> = ({history}) => { */
  const RelationshipStatus: React.FC = () => {
  const history = useHistory();  
  const [rel_status, setRelStatus] = useState<string>("");
  const [comeFrom, setComeFrom] = useState<any>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const user = useContext(UserContext);

  const [submitButton, setSubmitButton] = useState<string>("Finish");
  const [editData, setEditData] = useState<any>("");

  useIonViewWillEnter(() => { 
      if(localStorage.getItem('_userData') !== null){
          const editDataJson = localStorage.getItem('_userData');
          setComeFrom(localStorage.getItem('comeFrom'));
          const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
          setEditData(data);
          setRelStatus(String(data.rel_status));
          setSubmitButton('Update');
      }else{
        setSubmitButton('Finish');
        setEditData(null)
      } 
  });

  const onRelationShipStatus = async (rel_status: string) => {
    setErrorMessage('');
    if(localStorage.getItem("registerData") != null){
      const registerDataJson = localStorage.getItem("registerData");
      const replationJson = registerDataJson !== null ? JSON.parse(registerDataJson) : '';
      replationJson.rel_status = rel_status;
      setRelStatus(String(rel_status));
      localStorage.setItem('registerData', JSON.stringify(replationJson));
    }else{
      setRelStatus(rel_status);
    }
  }
  
  const onClickRelationship =() =>{

      setShowToast(false);
      //if(rel_status){
        setShowLoading(true);
          if(!editData){
            // Register Data
            if(localStorage.getItem('_isLogin') !== 'true'){
              const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: localStorage.getItem('registerData')
              }; 
        
              fetch(API.API_LIVE + 'register', requestOptions).then(async response => {
                const data = await response.json();
                if(data.status !== false){
                  localStorage.removeItem('registerData');
                  localStorage.setItem('_isLogin', 'true');
                  localStorage.setItem('_token', data.token);
                  localStorage.setItem('_userData', JSON.stringify(data.user));
                  history.push('/home', {direction: 'none'});
                  await user.setIsLoggedIn(true);
                }else{
                  setErrorMessage(data.message);
                }
                setToastMessage(data.message);
                setShowToast(true);
                setShowLoading(false);

              }).catch(error => {
                setErrorMessage(error.toString());
                setShowLoading(false);
                  
              });

            }else{
              history.push('/home', {direction: 'none'});
            }
            
            // Register Data
          }else{
            // Edit Data
              const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token')},
                body: JSON.stringify({ student_id: editData.id, rel_status: rel_status})
              };
              fetch(API.API_LIVE + 'account/update_bio', requestOptions).then(async response => {
                const data = await response.json();
                if(data.status !== false){
                  localStorage.setItem('comeFrom', 'edit');
                  history.goBack(); 
                  setErrorMessage('');
                  setToastMessage(data.message);
                  setShowToast(true);
                }else{
                  if(data.code === '401'){
                    goLogout(data.message);
                  }else{
                    setToastMessage(data.message);
                    setShowToast(true);
                  };
                }
              setShowLoading(false);
              }).catch(error => {
                setErrorMessage(error.toString());
                setShowLoading(false);
                  
              });
            // Edit Data
          }
          
      /* }else{
        setErrorMessage('Please select replationship');
      }  */
  }

  const goBackPage = ()=>{ history.goBack(); }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }
  return (
    <IonPage>
       {editData && <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="dark">
             {/* <IonBackButton defaultHref="/profile" text="" /> */}
             {comeFrom === 'edittable' && <IonButton color="primary" onClick={goBackPage}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>}
      <IonHeader>
        <IonToolbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-top extraPad">

      <IonGrid className="profilePic ion-padding-horizontal">
        
        <IonRow className="ion-justify-content-between profileContent">
          <IonCol size="12" className="headContent ion-text-center">
            <h2 className="">Relationship Status?</h2>
            {comeFrom === 'edittable' && <br />}
            {comeFrom !== 'edittable' && <p>Last Question!!!!</p>}
            {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
            <IonLoading isOpen={showLoading} backdropDismiss={true}  message="Loading..." onDidDismiss={()=> setShowLoading(false)}/>
            <IonRadioGroup value={rel_status} onIonChange={e => onRelationShipStatus(e.detail.value)}>
              <IonItem>
                <IonLabel>Single</IonLabel>
                <IonRadio value="1" />
              </IonItem>

              <IonItem>
                <IonLabel>In A Relationship</IonLabel>
                <IonRadio value="2" />
              </IonItem>
            </IonRadioGroup>

            {errorMessage && <IonText color="danger"> <p className="dangerMessage">
            {errorMessage} </p> </IonText>}
          </IonCol>
         
          {!showLoading && <IonCol size="12">
            <IonButton color="primary" onClick={onClickRelationship}  expand="block">{submitButton}</IonButton>
          </IonCol>}

        </IonRow>
      </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default RelationshipStatus;
