import React, { useState, useRef, useContext, useCallback, createContext } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonLabel, useIonViewWillEnter, IonButtons, IonAvatar, IonList, IonProgressBar, IonBackButton, IonButton, IonTitle, IonToast, IonListHeader, IonFab, IonFabButton, IonIcon, IonRefresher, IonRefresherContent } from '@ionic/react';
import '../my-class/MyClass.css';
import { API, AppLogout } from '../../constants';
import { useHistory } from 'react-router';
import { UserContext } from "../../App";
import { arrowBack, person } from 'ionicons/icons';

import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';

interface Person {
  id: number;
  first_name: string;
  last_name: string;
  photo: string;
  photo_url: string;
  photo_url_100: string;
  dob: string;
  is_birthday: number
}

const BirthdayList: React.FC = (props) => {

  const history = useHistory();
  const user = useContext(UserContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const contentRef: any = useRef(null);
  const [showLoading, setShowLoading] = React.useState(false);
  const [listData, setListData] = useState<Person[]>([]);
  const [arrayData, setArrayData] = useState<any>([1,2,3,4,5,6,7,8]);
  const [comeFrom, setComeFrom] = useState<any>("");
  const [noResultText, setNoResultText] = useState<any>("Loading...");

  const scrollToTop= () => { contentRef.current.scrollToTop(); };

    /*   useIonViewWillEnter(async() => {
      const comeFromPage = localStorage.getItem('comeFrom');
      setComeFrom(comeFromPage);
      fetchSearchListData();
    });
    */
 
    const sendGetRequest = () => { 
      return fetch(API.API_LIVE+'account/birthdays',{ method:'GET', headers: {'Authorization': 'Bearer ' + localStorage.getItem('_token')}}).then(async response => {
        return response.json();;
      })
    };

    React.useEffect(() => {
      const comeFromPage = localStorage.getItem('comeFrom');
      setComeFrom(comeFromPage);
      
      setShowLoading(true);
      sendGetRequest().then(data => {
        if(data.length > 0){
          setNoResultText('');
          setListData(data);
        }else{
          if(data.code === '401'){
            goLogout(data.message);
          }else{
            setNoResultText('No result found.');
            setListData([]);
          }
        }
        setShowLoading(false);
      });
    }, []);


  /* function fetchSearchListData(){
    setShowLoading(true);

    fetch(API.API_LIVE+'account/birthdays',{ method:'GET', headers: {'Authorization': 'Bearer ' + localStorage.getItem('_token')}}).then(async response => {
     let data = await response.json();

      if(data.length > 0){
        setNoResultText('');
        setListData(data);
      }else{
        if(data.code === '401'){
          goLogout(data.message);
        }else{
          setNoResultText('No result found.');
          setListData([]);
        }
      }
      setShowLoading(false);
    }).catch(error => {
        console.log('error');
    });
  } */

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }  
  
  const goBackHome = () =>{  (props as any).history.goBack(); }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
      localStorage.setItem('pullTorefresh', 'yes');
      setShowLoading(false);
      sendGetRequest();
      setTimeout(() => {
        scrollToTop();
        event.detail.complete();
      }, 2000);
  }


  return (
    <IonPage className="searchPage">
      <IonHeader className="my__class__header">
        {showLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}
        <IonToolbar>
          <IonButtons slot="start"> 
              {comeFrom !== 'home' && <IonBackButton defaultHref="/home" color="primary" text="" />}
              {comeFrom !== 'birthday-list' && <IonButton color="primary" onClick={goBackHome}>
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>}
          </IonButtons>
          <IonTitle className="headTitle ion-text-left">Upcoming Birthdays</IonTitle>
       </IonToolbar>
      </IonHeader>

      <IonContent className="birthday__listing" ref={contentRef} scrollEvents={true}>
        {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}

      {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton onClick={scrollToTop}>
        <IonIcon icon={chevronUpCircleOutline} />
      </IonFabButton>
      </IonFab> */}
      
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing...">
        </IonRefresherContent>
      </IonRefresher>

        {listData.length !== 0 && <IonList>
          {listData.map((list: any, i: number) => {
            return <div key={`${i}`}>
              {list.month !== 'Todays' && list.data.length > 0 && <IonListHeader> {list.month} </IonListHeader>}
              {list.data.map((student: any, idx: number) => {
                  return <div key={idx} >
                      {list.month !== 'Todays' && <EmployeeItem person={student} />}
                  </div>
              })}
            </div> 
          })}
        </IonList>}

        {noResultText && <IonLabel className="d-w-c">{noResultText}</IonLabel>} 

        {listData.length === 0 && <IonList>
          {arrayData.map((i: number) => {
            return <IonItem key= {`${i}`}>
              <IonAvatar slot="start">
                <div className="listAvatar skeleton"></div>
              </IonAvatar>
              <IonLabel>
                <div className="listName skeleton"></div>
              </IonLabel>
              <IonAvatar className="shouting__pan">
                <div className="listAvatar skeleton"></div>
              </IonAvatar>
            </IonItem>
          })}
        </IonList>}
        
      </IonContent>
    </IonPage> 
  );
};

const EmployeeItem: React.FC<{ person: Person }> = ({ person }) => {
  const history = useHistory();
    const birthdayPage = (birthday : any) =>{
    localStorage.setItem('listData', JSON.stringify(birthday));
    localStorage.setItem('comeFrom', 'birthday-list');
    history.push('/give-some', {direction: 'back'});
  }

  const onClickUserprofile = (student_id : number, name: string)=>{
    const jsonData = {id:student_id, first_name: name, last_name: ''}; 
    localStorage.setItem('listData', JSON.stringify(jsonData));
    localStorage.setItem('comeFrom', 'birthday-list'); 
    history.push('/profile-details', {direction: 'none'});
  }
  
  return (
    <IonItem className="my__class__items">
      <IonAvatar slot="start" className="listAvatar" onClick={()=> onClickUserprofile(person.id, person.first_name)}>
        <img src={(person.photo_url) ?   person.photo_url : 'assets/img/default.svg'} alt="User Profile"/>
      </IonAvatar>
      <IonLabel>
        <h4 className="font-medium">{person.first_name} {person.last_name}</h4>
        <p>{person.dob}</p>
      </IonLabel>
      
      {/* {person.is_birthday === 1 && <IonAvatar slot="end" className="birthday__shout__icon" onClick={()=>birthdayPage(person)}>
        <img src='assets/img/birthday__shout.svg' alt="Birthday Shout" />
      </IonAvatar>}
      */}  
      <IonAvatar slot="end" className="birthday__shout__icon" onClick={()=>birthdayPage(person)}>
        <img src='assets/img/birthday__shout.svg' alt="Birthday Shout" />
      </IonAvatar>

    </IonItem>
  );
}

export default BirthdayList;
