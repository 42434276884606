import React, { useState, useRef, useContext } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonLabel, useIonViewWillEnter, IonButtons, IonAvatar, IonSearchbar, IonList, IonInfiniteScroll, IonInfiniteScrollContent, IonProgressBar, IonText, IonBackButton, IonTitle, IonButton, IonToast, IonIcon, IonActionSheet, IonModal, IonCheckbox, IonRow, IonCol, IonRefresher, IonRefresherContent, IonBackdrop } from '@ionic/react';
import './MyClass.css';
import { API, AppLogout } from '../../constants';
import { RouteComponentProps, useHistory } from 'react-router';
import { Plugins, KeyboardInfo, Capacitor} from '@capacitor/core';
import { UserContext } from "../../App";
import { filter } from 'ionicons/icons';

import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBack } from 'ionicons/icons';

const { Keyboard } = Plugins;

interface OwnProps extends RouteComponentProps {}
interface MyClassProps extends OwnProps { }
interface searchTextData { id: number, name: any } 

interface Person {
  id: number;
  first_name: string;
  last_name: string;
  photo: string;
  photo_url: string;
}

//const MyClass: React.FC<MyClassProps> = ({history}) => {
  
const MyClass: React.FC = () => {

  const history = useHistory();
  const user = useContext(UserContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const contentRef: any = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  const [listData, setListData] = useState<Person[]>([]);
  const [userData, setUserData] = useState<any>("");

  const [arrayData, setArrayData] = useState<any>([1,2,3,4,5,6,7,8]);

  const [pageId, setPageId] = useState<number>(1);
  const [comeFrom, setComeFrom] = useState<any>("");
  const [noResultText, setNoResultText] = useState<any>("Loading...");
  const [onSearchChange, setOnSearchChange] = useState<number>();
  const [searchText, setSearchText] = useState('');

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [singleChecked, setSingleChecked] = useState(false);
  const [relationshipChecked, setRelationshipChecked] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
 
  if(localStorage.getItem('_isLogin') !== 'false'){
    const userDataJson = localStorage.getItem('_userData');
    if(localStorage.getItem('_userData') !== null && userData === ''){
       const data = userDataJson !== null ? JSON.parse(userDataJson) : '';
       setUserData(data);
    }
  }

  const scrollToTop= () => { contentRef.current.scrollToTop(); };

  useIonViewWillEnter(async() => {
    //onSetSearchText('');
   /*  console.log('singleChecked:' + singleChecked);
    console.log('relationshipChecked:' + relationshipChecked);
 */
    //setSearchText('');
    const comeFromPage = localStorage.getItem('comeFrom');
    if(comeFromPage !== null){ 
     setComeFrom(comeFromPage);
    }else{ setComeFrom(''); }

    if(comeFromPage === 'home'){ 
      setSingleChecked(false); setRelationshipChecked(false); 
    }

    if(localStorage.getItem('_listData') !== null){ 
     /*  if(comeFromPage === null){
        fetchSearchListData('', 1);
      }else{ */
        const listDataJson = localStorage.getItem('_listData');
        const list_data = listDataJson !== null ? JSON.parse(listDataJson) : '';
        setListData(list_data);
        setNoResultText('');
      //}
    }else{
      if(localStorage.getItem('scrollTop') === 'yes'){ 
        setSearchText('');
        scrollToTop(); localStorage.removeItem('scrollTop'); 
      }
      fetchSearchListData('', 1, singleChecked, relationshipChecked);
    }    
  });

  //New Search Code 
  const onSetSearchText = (searchText : any, nextPageId: number = 1) => {
    //setSearchText(searchText);
    localStorage.removeItem('_listData');
    if(searchText !== ''){ 
      if(onSearchChange !== searchText.length){ 
        setSearchText(searchText);
        fetchSearchListData(searchText, nextPageId, singleChecked, relationshipChecked);      
      }
    } else { 
      setSearchText(''); 
      setOnSearchChange(0);
      if(localStorage.getItem('_listData') !== null) { 
        const listDataJson = localStorage.getItem('_listData');
        const list_data = listDataJson !== null ? JSON.parse(listDataJson) : '';
        setNoResultText('');
        setListData(list_data);
      } else {
        fetchSearchListData('', nextPageId, singleChecked, relationshipChecked);
      }    
    }
    //New Search Code 
  }

  async function searchNext($event: CustomEvent<void>) {
    if(pageId){
      const nextPageId = pageId + 1;
      setPageId(nextPageId);
      await fetchSearchListData(searchText, nextPageId, singleChecked, relationshipChecked);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  function fetchSearchListData(searchText : string, nextPageId: number, singleChecked : boolean, relationshipChecked: boolean){

    if(searchText === ''){ setSearchText('');}
    
    setShowLoading(true);
    const comeFromPage = localStorage.getItem('comeFrom');
    var isProfile : any = '';
    if(comeFromPage !== null){ 
      if(comeFromPage === 'home'){
        isProfile = 1;
      }else{
        isProfile = 0;
      }
    }else{ isProfile = 0; }

    var single : any = 0; var inRelation: any= 0;
    if(singleChecked) { single = 1;}else{ single = '';}
    if(relationshipChecked) { inRelation = 2;}else{ inRelation = '';}
    
    var relation_status = '';
    if(single !== '' && inRelation === ''){
      relation_status = single;
    }else if(inRelation !== '' && single === '' ){
      relation_status = inRelation;
    }else if(single !== '' && inRelation !== ''){
      relation_status = single + ','+inRelation;
    }else{
      relation_status = '';
    }

    fetch(API.API_LIVE+'student/search?keyword='+searchText+'&student_id='+userData.id + '&page_id='+ nextPageId + '&is_profile='+ isProfile + '&rel_status=' + relation_status, { method:'GET', headers: {'Authorization': 'Bearer ' + localStorage.getItem('_token')}}).then(async response => {

     let data = await response.json();
      if(data.length > 0){
        setNoResultText('');
        let finalData = [];
        setDisableInfiniteScroll(false);
        if(nextPageId === 1){ finalData = data;
        }else{
          const listA =listData;
          finalData = listA.concat(...data);
        }
        localStorage.setItem('_listData', JSON.stringify(finalData));
        setListData(finalData);
      }else{
        if(data.code === '401'){
          goLogout(data.message);
        }else{
         // setNoResultText('No result found.');
          console.log('disableInfiniteScroll:' + disableInfiniteScroll);
          if(disableInfiniteScroll){ 
            setNoResultText(''); 
          }else{
            setNoResultText('No result found.');
          }

          if(nextPageId === 1){ setListData([]); }
          setDisableInfiniteScroll(true);
        }
      }
      setPageId(nextPageId);
      setShowLoading(false);
    }).catch(error => {
      setDisableInfiniteScroll(true);
        
    });
  }

  const onCancel1 = (event : any) => { }
  
  const onHideKeyBoard = () =>{ 
    if(Capacitor.platform !== 'web')
    Keyboard.hide(); 
  }
  const openKeyboard =() =>{ 
    if(Capacitor.platform !== 'web')
    Keyboard.show(); 
  }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }  

 /*  const onApply = async()=>{
    setShowModal(false); 
    setShowCheckBox(true);
    await fetchSearchListData(searchText, 1, singleChecked, relationshipChecked);
  }

  const onCancel = ()=>{
    if(singleChecked){ setSingleChecked(false); }
    if(relationshipChecked){ setRelationshipChecked(false); }
    setShowModal(false); setShowCheckBox(false); 
  } */
  
  const onCloseS = async()=>{
    setSingleChecked(false); setShowModal(false); 
    await fetchSearchListData(searchText, 1, false, relationshipChecked);    
  }

  const onCloseR = async()=>{
    setRelationshipChecked(false); setShowModal(false); 
    await fetchSearchListData(searchText, 1, singleChecked, false);
  }

  const loadListData = ()=>{ 
    fetchSearchListData(searchText, 1, singleChecked, relationshipChecked);
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setShowLoading(false);
    fetchSearchListData(searchText, 1, singleChecked, relationshipChecked);
    setTimeout(() => {
      scrollToTop();
      event.detail.complete();
    }, 2000);
  }

  const goBackHome = () =>{ history.goBack(); }

  return (
    <IonPage className="searchPage">
      <IonHeader className="my__class__header">
       {showLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}
        {comeFrom ==='home' && <IonToolbar>
          <IonButtons slot="start">
              {/* <IonButton color="secondary" onClick={goBackHome}>
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton> */}
            <IonBackButton defaultHref="/home" color="primary" text=""  />

          </IonButtons>
          <IonTitle className="headTitle ion-text-left">Class Directory</IonTitle>

          <IonButtons slot="end" onClick={() => setShowModal(true)}>
            <IonButton slot="icon-only" shape="round" color="medium">
              <IonIcon icon={filter}  />
            </IonButton>
          </IonButtons>

          {/* <IonButtons slot="end">
            <IonButton expand="full" fill="clear" color="primary" onClick={onHideKeyBoard}>Cancel</IonButton>
          </IonButtons> */}
          {/* <IonTitle>Choose a Classmate</IonTitle> */}

       </IonToolbar> }

        {comeFrom === '' && <IonToolbar>
          <IonTitle className="headTitle px-15 ion-text-left">Class Directory</IonTitle>
          <IonButtons className="filter__btn" slot="end" onClick={() => setShowModal(true)}>
            <IonButton slot="icon-only" shape="round" color="tertiary">
              <IonIcon icon={filter} /> 
            </IonButton>
          </IonButtons>
       </IonToolbar>}

   

        <IonToolbar>
          <IonSearchbar className="no-padding-bottom" value={searchText} onIonChange={e => onSetSearchText(e.detail.value!)} onIonCancel={onCancel1} placeholder="Choose a Classmate" enterkeyhint="search" onIonFocus={openKeyboard} onIonBlur={onHideKeyBoard}></IonSearchbar>
        </IonToolbar>

          {( singleChecked || relationshipChecked ) && <IonToolbar>
          <div className="d-flex align-items-center  ion-padding-horizontal">
            {singleChecked && <div className="filter__selected">
              <div>Single</div>
              <span className="filter__close" onClick={onCloseS}><i className="material-icons">close</i></span>
            </div>}

            {relationshipChecked && <div className="filter__selected">
              <div>In A Relationship</div>
              <span className="filter__close" onClick={onCloseR} ><i className="material-icons">close</i></span>
            </div>}
          </div>
        </IonToolbar>}
      </IonHeader>
      <IonContent className="" ref={contentRef} scrollEvents={true} onIonScroll={onHideKeyBoard}>
      
        
      
        {/* <IonButton className="fixed__btn__close" onClick={() => setShowModal(false)}></IonButton> */}


        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>
        
        <IonModal isOpen={showModal} cssClass='filter__modal' onDidDismiss={() => setShowModal(false)} swipeToClose={true} backdropDismiss={true}  >
          
          <span className="dash__span"></span>
          <div className="checkBox__Heading">Relationship Status</div>
          <IonList>
            <IonItem>
              <IonCheckbox checked={singleChecked} onIonChange={e => setSingleChecked(e.detail.checked)} onClick={loadListData} />
              <IonLabel>Single</IonLabel>
            </IonItem>
            <IonItem>
            <IonCheckbox checked={relationshipChecked} onIonChange={e =>setRelationshipChecked(e.detail.checked)} onClick={loadListData} />
              <IonLabel>In a Relationship</IonLabel>
            </IonItem>
          </IonList>

           {/* <IonRow className="p-8">
            <IonCol size="6">
              <IonButton shape="round" expand="full" color="primary" onClick={onApply} >Apply</IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton shape="round" expand="full" color="light" onClick={onCancel} >Cancel</IonButton>
            </IonCol>
          </IonRow> */}

        </IonModal>
        {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)}/>}
        
        {listData.length !== 0 && <IonList className="ion-no-padding">
          {listData.map((list, idx:number) => <EmployeeItem key={idx} person={list} />)}
          
          <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent loadingText="Loading more ..."></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonList>}
       
        {noResultText && listData.length === 0 &&  <IonLabel className="d-w-c">{noResultText}</IonLabel>} 
      
        {listData.length === 0 && noResultText !=='No result found.' && <IonList>
          {arrayData.map((i: number) => {
            return <IonItem key= {`${i}`}>
              <IonAvatar slot="start">
                <div className="listAvatar skeleton"></div>
              </IonAvatar>
              <IonLabel>
                <div className="listName skeleton"></div>
              </IonLabel>
            </IonItem>
          })}
        </IonList>}
      </IonContent>
    </IonPage> 
  );
};

const EmployeeItem: React.FC<{ person: Person }> = ({ person }) => {

  const history = useHistory();

  const onListClick = (person : any) =>{
    localStorage.setItem('listData', JSON.stringify(person));
    const comeFromPage = localStorage.getItem('comeFrom');
    if(comeFromPage !== null){ 
      if(comeFromPage === 'home'){
        history.push('/give-some', {direction: 'none'});
      }else{
        history.push('/profile-details', {direction: 'none'});
      }
    }else{
      history.push('/profile-details', {direction: 'none'});
    }
  }

  return (
    <IonItem className="my__class__items" onClick={() => onListClick(person)}>
      <IonAvatar slot="start" className="listAvatar">
        <img src={(person.photo_url) ?   person.photo_url : 'assets/img/default.svg'} alt="User Profile"/>
      </IonAvatar>
      <IonLabel>
        <h4 className="font-medium">{person.first_name} {person.last_name}</h4>
      </IonLabel>
    </IonItem>
  );
}

export default MyClass;
