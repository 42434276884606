import React, { useState } from "react";
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { Redirect, Route } from "react-router";
import { mapSharp, people, person } from 'ionicons/icons';
import { useLocation } from 'react-router-dom'

import Login from '../pages/login/Login';
import Home from '../pages/home/Home';
import MyClass from '../pages/my-class/MyClass';
import Profile from '../pages/profile/Profile';
import ProfileDetails from '../pages/profile-details/ProfileDetails';

import JoinClass from '../pages/join-class/JoinClass';
import YouLike from '../pages/you-like-to-do/YouLike';
import Favorite from '../pages/favorite/Favorite';
import BeforeGSB from '../pages/before-gsb/BeforeGSB';
import RelationshipStatus from '../pages/relationship-status/RelationshipStatus';
import GiveSome from '../pages/give-some/GiveSome';
import Birthday from '../pages/birthday/Birthday';
import BirthdayList from '../pages/birthday-list/BirthdayList';

const MainTabs: React.FC = () => {

  const onTabClick = ()=>{ 
    localStorage.removeItem('comeFrom');
  }
  const location = useLocation();
  return (
        <IonTabs>
          <IonRouterOutlet>
           {/*  <Redirect exact path={(location.pathname === '/join-class-edit' || location.pathname === '/you-like-to-do-edit' || location.pathname ==='/before-gsb-edit' || location.pathname ==='/relationship-status-edit' || location.pathname ==='/profile-details' || location.pathname === '/give-some' || location.pathname === '/favorite-edit' || location.pathname === '/birthday-edit' || location.pathname === '/birthday-list' || location.pathname === '/home' || location.pathname === '/profile' ) ? '/' : ''} to="/home" /> */}

           <Redirect exact path="/" to="/home" /> 
            
           {/*  <Route path="/" component={ isLoggedIn ? MainTabs : JoinClass } /> */}
            <Route exact path="/home" render={() => <Home />} />
            <Route exact path="/my-class" render={() => <MyClass />} />
            <Route exact path="/profile" render={() => <Profile />} />
            <Route exact path="/profile-details" render={() => <ProfileDetails />} />
            <Route exact path="/join-class-edit" render={() => <JoinClass />} />
            <Route exact path="/you-like-to-do-edit" render={() => <YouLike />} />
            <Route exact path="/favorite-edit" render={() => <Favorite />} />
            <Route exact path="/before-gsb-edit" render={() => <BeforeGSB />} />
            <Route exact path="/relationship-status-edit" render={() => <RelationshipStatus />} />
            <Route exact path="/birthday-edit" render={() => <Birthday/>} />
            <Route exact path="/give-some" render={() => <GiveSome />} />
            <Route exact path="/birthday-list" render={() => <BirthdayList />} />
            
          {/*   <Route exact path="**" to="/home"/> */}

           {/*  <Route path="/home" component={Home} exact={true} />
            <Route path="/my-class" component={MyClass} exact={true} /> 
            <Route path="/profile" component={Profile} exact={true}/>
            <Route path="/profile-details" component={ProfileDetails}  exact={true}/>
            <Route path="/join-class-edit" component={JoinClass} exact={true} />
            <Route path="/you-like-to-do-edit" component={YouLike} exact={true} />
            <Route path="/favorite-edit" component={Favorite} />
            <Route path="/before-gsb-edit" component={BeforeGSB} exact={true} />
             <Route path="/relationship-status-edit" component={RelationshipStatus} exact={true} />
             <Route path="/give-some" component={GiveSome}  exact={true}/>
            */}
          
        </IonRouterOutlet>
         
        <IonTabBar slot="bottom" onClick ={() =>onTabClick()} className={(location.pathname === '/join-class-edit' || location.pathname === '/you-like-to-do-edit' || location.pathname ==='/before-gsb-edit' || location.pathname ==='/relationship-status-edit' || location.pathname ==='/profile-details' || location.pathname === '/give-some' || location.pathname === '/favorite-edit' || location.pathname === '/birthday-edit' || location.pathname === '/birthday-list' ) ? 'ion-hide' : ''}>
          <IonTabButton tab="home" href="/home" >
          <IonIcon icon={mapSharp} /><IonLabel >Home </IonLabel>
          </IonTabButton>
          <IonTabButton tab="my-class" className="myclass_activate" href="/my-class">
            <IonIcon icon={people} /><IonLabel>Our Class</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={person} /><IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;