
import React, { useState, useContext } from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonRow, IonCol, IonInput, IonButton, IonGrid, IonText, useIonViewWillEnter, IonToast, IonButtons, IonBackButton, IonIcon} from '@ionic/react';
import './BeforeGSB.css';
import { API, AppLogout } from '../../constants';
import { arrowBack } from 'ionicons/icons';
import { RouteComponentProps, useHistory} from 'react-router';
import { UserContext } from "../../App";

interface DispatchProps { }
interface OwnProps extends RouteComponentProps { }
interface BeforeGSBProps extends OwnProps, DispatchProps { }
interface Experience { id: number; institute_name: any; role_of_study: any; }

/* const BeforeGSB: React.FC<BeforeGSBProps> = ({ history }) => { */
const BeforeGSB: React.FC= () => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [institute_name, setInstituteName] = useState<string>("");
  const [role_of_study, setRoleOfStudy] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [instituteNameError, setInstituteNameError] = useState(false);
  const [roleOfStudyError, setRoleOfStudyError] = useState(false);
  const [experienceData, setExperienceData] = useState<Experience[]>([]);
  const [comeFrom, setComeFrom] = useState<any>("");
  const [showLoading, setShowLoading] = useState(false);
  const [submitButton, setSubmitButton] = useState<string>("Next");
  const [editData, setEditData] = useState<any>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);  

  const onInstituteNameChnage = (institute_name: any, index:number) => {
    if (institute_name.length >= 0) { setInstituteNameError(false);
      experienceData[index].institute_name = institute_name; setFormSubmitted(false);
      localStorage.setItem('experiaceData', JSON.stringify(experienceData));
    } else { setInstituteNameError(true); }
  }
  
  const onRoleOfStudyChnage = (role_of_study: any, index: number) => {
    if (role_of_study.length >= 0) { setRoleOfStudyError(false);
      experienceData[index].role_of_study = role_of_study;  setFormSubmitted(false);
      localStorage.setItem('experiaceData', JSON.stringify(experienceData));
    } else { setRoleOfStudyError(true); }
  }

  useIonViewWillEnter(() => { 
    if(localStorage.getItem('_userData') !== null){
      const editDataJson = localStorage.getItem('_userData');
      setComeFrom(localStorage.getItem('comeFrom'));
        const data = editDataJson !== null ? JSON.parse(editDataJson) : '';
        setEditData(data);
        setExperienceData(data.experiences);
        setSubmitButton('Update');   
    }else{
      setSubmitButton('Next');
      setEditData(null)
    } 
  });

  getExpData();

  function getExpData(){
    if (localStorage.getItem('experiaceData') !== null) {
      const extDataJson = localStorage.getItem('experiaceData');
      const expData = extDataJson !== null ? JSON.parse(extDataJson) : '';
        if(experienceData.length === 0){
          setExperienceData(expData);
        }
    }else{
      const data = [{ id: 1, institute_name: institute_name, role_of_study: role_of_study }];
      localStorage.setItem('experiaceData', JSON.stringify(data));
      setExperienceData(data);
    }
  }

  const onFormSubmitClick = () => {
    setFormSubmitted(true);
    
    let lastIndex = experienceData.length - 1;
    let tempFlag = true;

    if(experienceData[lastIndex].institute_name === ''){
      setInstituteNameError(true); tempFlag = false;  
    }
    if(experienceData[lastIndex].role_of_study === ''){
      setRoleOfStudyError(true); tempFlag = false; 
    }

    if(tempFlag === true){
      const idd = experienceData.length + 1;
      const data = { id: idd, institute_name: institute_name, role_of_study: role_of_study };
      experienceData.push(data);
      localStorage.setItem('experiaceData', JSON.stringify(experienceData));
      setExperienceData(experienceData);
    }

   /*  setFormSubmitted(true);
    if (institute_name) {
      if (role_of_study) {

        if (localStorage.getItem('experiaceData') != null) {
          const extDataJson = localStorage.getItem('experiaceData');
          const expData = extDataJson !== null ? JSON.parse(extDataJson) : '';
          const idd = parseInt(expData.length) + 1;
          const data = { id: idd, institute_name: institute_name, role_of_study: role_of_study };
          expData.push(data);
          setExperienceData(expData);
          localStorage.setItem('experiaceData', JSON.stringify(expData));

        } else {
          
          const data = [{ id: 1, institute_name: institute_name, role_of_study: role_of_study }];
          localStorage.setItem('experiaceData', JSON.stringify(data));
          const dataJson = localStorage.getItem('experiaceData');
          const emptyData = dataJson !== null ? JSON.parse(dataJson) : '';

          const idd = parseInt(emptyData.length) + 1;
          const emptyData1 = { id: idd, institute_name: '', role_of_study: '' };
          emptyData.push(emptyData1);
          localStorage.setItem('experiaceData', JSON.stringify(emptyData));

          setExperienceData(emptyData);
        }
        setInstituteName(''); setRoleOfStudy('');
      
      } else { setRoleOfStudyError(true); }
    } else { setInstituteNameError(true); } */
  };
  

  const onClickNext = async () => {
    const registerDataJson = localStorage.getItem('registerData');
    if (localStorage.getItem('experiaceData') != null) {

      const extDataJson = localStorage.getItem('experiaceData');
      const registerData = registerDataJson !== null ? JSON.parse(registerDataJson) : '';
      const expData = extDataJson !== null ? JSON.parse(extDataJson) : '';

      let lastIndex = expData.length - 1;
      let tempFlag = true;

      if(expData[lastIndex].institute_name === ''){
        setInstituteNameError(true); tempFlag = false;  
      }
      if(expData[lastIndex].role_of_study === ''){
        setRoleOfStudyError(true); tempFlag = false; 
      }

      if(expData[lastIndex].institute_name === '' && expData[lastIndex].role_of_study === ''){
        setInstituteNameError(false);  setRoleOfStudyError(false); tempFlag = true; 
      }
     
      if(tempFlag === true){
        if(!editData){
          // Register Data
          registerData.experience = expData;
          localStorage.setItem('registerData', JSON.stringify(registerData));
          history.push('/birthday', {direction: 'none'});
          // Register Data
        }else{
          // Edit Data
            const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('_token') },
              body: JSON.stringify({ student_id: editData.id, experience: expData })
            };
            fetch(API.API_LIVE + 'account/add_experience', requestOptions).then(async response => {
              const data = await response.json();
              if(data.status !== false){
                localStorage.setItem('comeFrom', 'edit');
                history.goBack();
                setToastMessage(data.message);
                setShowToast(true);
              }else{
                if(data.code === '401'){
                  goLogout(data.message);
                }else{
                  setToastMessage(data.message);
                  setShowToast(true);
                }
              }
              setShowLoading(false);
            }).catch(error => {
              setShowLoading(false);
            });
          // Edit Data
        }
      } 
    }
  }
  const goBackPage = ()=>{ history.goBack(); }

  const goLogout = (message: any) => {
    setShowToast(true);
    setToastMessage(message);
    setTimeout(function(){ 
      AppLogout();
      history.replace('/login');
      user.setIsLoggedIn(false);
    }, 1500);
  }
  
  return (
    <IonPage>
      {editData && <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" color="dark">
             {/* <IonBackButton defaultHref="/profile" text="" /> */}
             {comeFrom === 'edittable' && <IonButton color="primary" onClick={goBackPage}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>}
          </IonButtons>
          <IonTitle>Experience</IonTitle>
        </IonToolbar>
      </IonHeader>}
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent className="">

      {toastMessage && <IonToast color="dark" isOpen={showToast} message={toastMessage}  duration={4000} position="top" onDidDismiss={() => setShowToast(false)} />}

        <IonGrid className="profilePic ion-padding-horizontal">
          <IonRow className="ion-justify-content-between profileContent">
            <IonCol size="12" className="headContent ion-text-center">
              <h2 className="">What’d Ya Do Before GSB?</h2>
              <p>Add your Work/School Experience</p>
              {experienceData.map((experience, index) => (
                <div key={index}>
                  <form noValidate onSubmit={onFormSubmitClick}>
                    <IonLabel className="textItem Dblock ion-text-left">Experience {index+1}</IonLabel>
                    <IonItem className="ion-margin-top">
                      <IonInput name="institute_name" type="text" placeholder="Institution Name" value={experience.institute_name} spellCheck={false} autocapitalize="off" onIonChange={e => onInstituteNameChnage(e.detail.value!, index)}
                        required ></IonInput>
                    </IonItem>
                      {instituteNameError && experience.institute_name === '' && <IonText color="danger">
                      <p className="ion-padding-start ion-text-left dangerMessage"> Institution name is required </p>
                    </IonText>}

                    <IonItem className="ion-margin-top">
                      <IonInput name="role_of_study" type="text" placeholder="Your Role / Course of Study" value={experience.role_of_study} spellCheck={false} autocapitalize="off" onIonChange={e => onRoleOfStudyChnage(e.detail.value!, index)} required ></IonInput>
                    </IonItem>

                    { roleOfStudyError && experience.role_of_study === '' && <IonText color="danger">
                      <p className="ion-padding-start ion-text-left dangerMessage"> Your Role / Course of Study is required </p>
                    </IonText>}
                  </form>
               </div> ))}
              <div className="ion-text-left ">
                <IonButton onClick={() => onFormSubmitClick()} className="ion-text-left pl-0 ion-no-padding" color="primary" type="submit" fill="clear" >
                  <IonLabel className="labelWithIcon ion-text-capitalize"  ><i className="material-icons">add</i> Add Another Experience</IonLabel>
                </IonButton>
              </div>
            </IonCol>
           
            <IonCol size="12">
            {!formSubmitted && <IonButton color="primary" onClick={() => onClickNext()} expand="block">{submitButton}</IonButton>}
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default BeforeGSB;
